import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import { Plus, Minus } from "lucide-react";
import { Input } from "../../../src/components-shadcn/ui/input";
import QuestionIcon from "../../assets/Icons/Tipping/question.svg";
import "./members.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTipjarData,
  getTipjarGroups,
  getTipjarMembers,
  postTipjarGroups,
} from "../../state/reducers/user/user.thunk";
import { ToastContainer } from "react-toastify";
import BackButton from "../globals/BackButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchJson, postJson } from "../../utils/fetch.utils";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useCreateDepartment } from "./state/server-state/useDepartmentServerState";
import { BASE_URL } from "../../constants/network";
import { toast } from "sonner";

export default function AddGroups() {
  
  const [group, setGroup] = useState("");
  const [percentage, setPercentage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const userAccount = useSelector((state) => state.account);
  const tipjar = useSelector((state) => state.tipjar);
  const [perc, setPerc] = useState(0);
  const createDepartment = useCreateDepartment();
  const isCreatingDepartment = createDepartment.isLoading;
  const { activeEntity, setActiveDepartment } = useTipJarLocalState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: tipJarInfo, mutate: tipJarInfocreateMutation } = useMutation({
    mutationKey: "tipjar-creation-details",
    mutationFn: async () => {
      try {
        const resp = await postJson(BASE_URL + "api/v1/tipjar/");

        return resp.data;
      } catch (error) {
        return error;
      }
    },
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleCreateDepartment = async () => {
    if (!group || group.length <= 0) {
      toast.error("Enter department name");
      return;
    }

    if (!activeEntity) {
      toast.error("Some data is missing");
      return;
    }

    const payload = {
      name: group,
      distribution_points: percentage,
      entity_id: activeEntity?.id,
    };

    try {
      const response = await createDepartment.mutateAsync(payload);
      if (response === 403) {
        navigate("/login/");
      }
      const newDepartment = response.data;
      setActiveDepartment(newDepartment);
      navigate("/tipjar/groups/edit/");
      toast.success("Department created successfully");
    } catch (error) {
      toast.error("Failed to create department");
    }
  };

  useEffect(() => {
    if (tipjar?.errorMessage === 403) {
      navigate("/login");
    }
    tipJarInfocreateMutation();
  }, []);

  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, []);
  useEffect(() => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);
  }, [percentage]);

  const handlePerc = (value) => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });

    const add = count + parseInt(value);
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(Math.max(value, minValue), maxValue);

    setPercentage(String(limitedValue));
  };
  const addPerc = () => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);

    if (isNaN(percentage)) {
      percentage = 0;
    }
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(
      Math.max(percentage + 10, minValue),
      maxValue
    );

    setPercentage(limitedValue);
  };
  const substractPerc = () => {
    let count = 0;
    tipjar.groups?.map((group) => {
      count += group?.percentage;
    });
    setPerc(count);

    if (isNaN(percentage)) {
      percentage = 0;
    }
    const minValue = 0;
    const maxValue = 100 - count;
    const limitedValue = Math.min(
      Math.max(percentage - 10, minValue),
      maxValue
    );

    setPercentage(limitedValue);
  };
  const handleIncrement = () => {
    setPercentage((prev) => Math.min(prev + 1, 10));
  };

  const handleDecrement = () => {
    setPercentage((prev) => Math.max(prev - 1, 0));
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setPercentage(Math.max(0, Math.min(10, value)));
    }
  };
  useEffect(() => {
    if (tipjar.tipjargroupResponse.id) {
      navigate(`/tipjar/groups/edit?group=${tipjar.tipjargroupResponse.id}`);
      tipjar.tipjargroupResponse = {};
    }
  }, [tipjar.tipjargroupResponse]);

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      {/* <div
        className="flex flex-row items-center mt-9 w-full"
        style={{ width: "100%" }}
      >
        <div
          className={`flex flex-row items-center py-2 pl-2 pr-6`}
          onClick={() => handleBack()}
        >
          <img src={back} className="" alt="rate-star" />
        </div>
      </div> */}
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>
      <div className="px-3 mt-6 w-full" style={{ width: "100%" }}>
        <h2 className="text-3xl font-semibold text-primary-pwa">
          New Department
        </h2>
        <p
          className="text-md font-semibold text-gray-400 mt-2"
          style={{ width: "95%" }}
        >
          Create department to manage and set up group distribution points
        </p>

        <div
          className={`
              flex flex-row drop-shadow-xl mt-8 justify-between relative
              focus:outline-primary-pwa
              `}
          style={{ width: "100%" }}
        >
          <div style={{ flexDirection: "column", width: "100%" }}>
            <p style={{ color: "#ADB6C0" }} className="mb-2 text-sm">
              Department Name
            </p>
            {/* <input
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              //   onKeyDown={(e) => handleEnterKey(e)}
              placeholder={"Enter department name"}
              className={`text-sm block p-4 pl-10 w-full focus:outline-none`}
              onFocus={(e) => {
                if (e.currentTarget === e.target) {
                  // setIsFocused(true);
                  //dispatch(searchActions.searchLoadError(""));
                }
              }}
              onBlur={(e) => {
                // setIsFocused(false);
              }}
            /> */}
            <Input
              placeholder="Enter department name"
              className="w-full border-[#C1F2F5] shadow-sm h-[55px]"
              onChange={(e) => setGroup(e.target.value)}
            />
          </div>
        </div>

        <p style={{ color: "#ADB6C0" }} className="mb-2 text-sm mt-5 italic">
          Set distribution points out of 10
        </p>

        <div className="flex items-center justify-center space-x-2 p-4 w-full">
          <button
            onClick={handleDecrement}
            className="w-[30%] h-12 bg-[#F2C773] rounded-md shadow-md flex items-center justify-center hover:bg-[#e0b665] transition-colors"
          >
            <Minus className="text-white" />
          </button>
          <Input
            type="text"
            value={`${percentage}`}
            onChange={handleInputChange}
            className="w-full border-[#C1F2F5] shadow-sm h-[55px] text-center  outline-none"
          />
          <button
            onClick={handleIncrement}
            className="w-[30%] h-[55px] bg-[#F2C773] rounded-md shadow-md flex items-center justify-center hover:bg-[#e0b665] transition-colors"
          >
            <Plus className="text-white" />
          </button>
        </div>

        <div
          onClick={() => setIsOpen(!isOpen)}
          style={isOpen ? { width: "100%" } : { width: "100%" }}
          className="mt-8 mb-5 flex flex-col py-5 px-5 bg-[#FFF9ED] rounded-sm"
        >
          <div className="flex flex-row justify-between items-center">
            <span className="text-base font-semibold text-slate-900">
              How does it work
            </span>
            <img src={QuestionIcon} className="" alt="question-mark" />
          </div>

          <span className="text-sm mt-2 font-semibold text-slate-600">
            Points between 0 and 10 determine the proportion of tips a team
            receives relative to other teams
          </span>
          <span className="text-sm mt-2 font-semibold text-slate-600">
            The total distributed amount always equals the total tip received
          </span>
        </div>
      </div>
      <div className="mb-4 mt-2 p-2">
        <button
          // disabled={tipjar.isLoading}
          className={`rounded-full p-2 h-[60px] bg-${
            perc + percentage > 90 ? "accent" : "accent"
          } px-3 text-white text-m mx-auto mt-2 w-full`}
          // onClick={() => {
          //   dispatch(
          //     postTipjarGroups(
          //       {
          //         name: group,
          //         percentage,
          //         tipjar_id: tipJarInfo?.id,
          //       },
          //       userAccount.userDetails.token
          //     )
          //   );
          // }}
          // disabled={perc >= 100}
          onClick={() => handleCreateDepartment()}
          type="button"
        >
          {isCreatingDepartment
            ? "CREATING DEPARTMENT..."
            : "CREATE DEPARTMENT"}{" "}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}
