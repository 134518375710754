import React, { useEffect, useState } from 'react';
import { ChevronLeft, ArrowRight } from 'lucide-react';
import { Input } from '../../components-shadcn/ui/input';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components-shadcn/ui/button';
import BackButton from '../globals/BackButton';
import { toast } from 'sonner';
import { 
  useGetWithdrawBalance, 
  useWithdrawFunds, 
  useGetProcessingBalance, 
  useMpesaSelfTip, 
  useFetchProfile 
} from './state/server-state/useFirstTimeTipServerState';

const WithdrawBalance = () => {
    const [withdrawBalance, setWithDrawBalance] = useState(0);
    const [canWithDraw, setCanWithDraw] = useState(true); // Set to true for testing
    const [balanceData, setBalanceData] = useState(null);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [profileData, setProfileData] = useState(null);
    const navigate = useNavigate();
    
    const { data, isLoading } = useGetWithdrawBalance();
    const { data: profile, isLoading: isProfileLoading } = useFetchProfile();
    const withdrawFunds = useWithdrawFunds();
    const { data: processingBalance } = useGetProcessingBalance();
    const mpesaSelfTip = useMpesaSelfTip();

    const handleWithdrawal = async () => {
      console.log("WITHDRAWAL AMOUNT ---->", withdrawalAmount);
      const amount = parseFloat(withdrawalAmount);
      if (!amount || isNaN(amount) || amount <= 0 || amount > withdrawBalance) {
        toast.error("Enter a valid amount")
        return;
      }
  
      const payload = {
        amount: amount
      };
  
      try {
        const response = await withdrawFunds.mutateAsync(payload);
        console.log(response.data, "Withdraw funds res");
        if (response?.success) {
          toast.success("Withdrawal successful");

        } else {
          toast.error("Withdrawal failed");
         
        }
        setWithdrawalAmount(''); // Clear input after successful withdrawal
      
      } catch (error) {
        console.log("WITHDRAWAL FAILED ---->", error);
        toast.error("Withdrawal failed");
       
      }
    };

    useEffect(() => {
      if (profile?.data?.data) {
        setProfileData(profile.data.data);
      }
    }, [profile]);

    useEffect(() => {
      if (processingBalance?.data?.data) {
        setBalanceData(processingBalance.data.data);
      }
    }, [processingBalance]);
    
    useEffect(() => {
      if (data?.data?.balance) {
        setWithDrawBalance(data.data.balance);
      }
    }, [data]);

    // Calculate total processing amount
    const totalProcessing = balanceData?.processing_transactions?.reduce(
      (sum, transaction) => sum + transaction.amount, 
      0
    ) || 0;

    // Calculate total balance
    const totalBalance = (balanceData?.current_balance || 0) + totalProcessing;

    const handleAmountChange=(e)=>{
      setWithdrawalAmount(e.target.value);
    }

    return (
      <div className="flex flex-col min-h-screen bg-white px-4 pt-4">
        <div className="w-full px-4">
          <BackButton onPress={() => navigate(-1)} />
        </div>

        <h1 className="text-[#50A2A7] text-2xl font-medium mb-6 mt-6">
          Withdraw your Shukran tip balance
        </h1>

        <div 
          className="flex items-center justify-between bg-[#E9B44B] text-white p-4 rounded-lg mb-4 cursor-pointer" 
          onClick={() => navigate("/verify-identity/")}
        >
          <div className="flex flex-col">
            <span className="text-sm font-semibold text-white">Account action required!</span>
            <span className="text-sm text-white">Complete verification to access withdrawals</span>
          </div>
          <ArrowRight className="h-5 w-5" color="white" />
        </div>

        <div className="relative flex items-center w-full">
          <Input 
            type="text"
            placeholder="Enter amount"
            onChange={handleAmountChange}
            disabled={!canWithDraw}
            className={`w-full rounded-lg p-4 pr-12 h-[50px] rounded-full ${
              canWithDraw 
                ? 'bg-[#F0FEFF] border-[#50A2A7]' 
                : 'bg-gray-50 border-[#929FB1]'
            }`}
          />
          <Button
            variant="ghost"
            size="icon"
            className={`absolute right-1 p-2 ${
              canWithDraw 
                ? 'bg-[#50A2A7] hover:bg-[#50A2A7]/90' 
                : 'bg-gray-200'
            } rounded-full`}
            onClick={handleWithdrawal}
          >
            <ArrowRight className="h-4 w-4" color='white'/>
          </Button>
        </div>

        {/* Current Balance Card */}
        <div className={`rounded-lg p-4 mt-6 ${
          canWithDraw ? 'bg-[#F0FEFF] border-[#50A2A7]' : 'bg-gray-50 border-[#929FB1]'
        } border`}>
          <p className="text-xs text-[#929FB1] mb-1">CURRENT BALANCE</p>
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold text-gray-700">
              {balanceData?.current_balance || 0} KES
            </p>
          </div>

          <div className={`rounded-lg mt-4`}>
          <div className="flex justify-between items-center mb-4">
            <div>
              <p className="text-xs text-[#929FB1] mb-1">PROCESSING</p>
              <p className="text-lg font-semibold text-gray-700">{totalProcessing} KES</p>
            </div>
            <Button 
              className="bg-[#50A2A7] hover:bg-[#50A2A7]/90 text-white rounded-full px-4"
            >
              Request Advance
            </Button>
          </div>

          {balanceData?.processing_transactions?.map((transaction, index) => (
            <div 
              key={index} 
              className={`pt-3 ${
                index !== 0 ? 'border-t border-gray-200' : ''
              }`}
            >
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-xs text-[#929FB1] mb-1">
                    Available on {new Date(transaction.available_on).toLocaleDateString()}
                  </p>
                  <p className="text-base text-gray-700">
                    {transaction.amount} {transaction.currency}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        </div>

        {/* Processing Balance Card with Transactions */}
        

        {/* Total Balance Card */}
        <div className={`rounded-lg p-4 mt-4 mb-6 ${
          canWithDraw ? 'bg-[#F0FEFF] border-[#50A2A7]' : 'bg-gray-50 border-[#929FB1]'
        } border`}>
          <p className="text-xs text-[#929FB1] mb-1">TOTAL BALANCE</p>
          <p className="text-lg font-semibold text-gray-700">{totalBalance} KES</p>
        </div>
      </div>
    );
};

export default WithdrawBalance;