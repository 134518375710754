import add from "../../assets/Icons/tipjar/add.png";

const AddButton = ({ title, onClick }) => {
  return (
    <div className="mt-4 w-fit w-full justify-center flex items-center">
      <button
        onClick={() => onClick()}
        // className="add_member"
        className="flex flex-row items-center p-4 rounded-full shadow-md rounded-full"
      >
        <img src={add} className="add" alt="add member" />
        <h6 className="ml-2 text-[#F2C773] font-semibold">{title}</h6>
      </button>
    </div>
  );
};

export default AddButton;
