import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ChevronLeft, ClipboardEdit, ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { Input } from '../../../components-shadcn/ui/input';
import { Button } from '../../../components-shadcn/ui/button';
import { Checkbox } from '../../../components-shadcn/ui/checkbox';
import { useSearchAccount } from '../../Members/state/server-state/useMembersServerState';
import { toast } from 'sonner';
import { useTipJarLocalState } from '../../Tipjar/state/local-state/useTipjarLocalState';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components-shadcn/ui/select';
import { debounce } from 'lodash';

// Custom hook for debounced search
const useDebounceSearch = (searchFn, delay = 1000) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const previousSearchTerm = useRef('');

  // Create a debounced version of the search function
  const debouncedSearch = useCallback(
    debounce(async (term) => {
      // Only search if the term is different from the previous search
      // and is not empty
      if (term && term.trim() && term !== previousSearchTerm.current) {
        setIsSearching(true);
        try {
          await searchFn(term);
          previousSearchTerm.current = term;
        } finally {
          setIsSearching(false);
        }
      }
    }, delay),
    [searchFn, delay]
  );

  // Effect to handle search term changes
  useEffect(() => {
    if (!searchTerm.trim()) {
      // If search term is empty, reset previous search term
      previousSearchTerm.current = '';
      return;
    }
    debouncedSearch(searchTerm);
    
    // Cleanup function
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch]);

  return { searchTerm, setSearchTerm, isSearching };
};

const getInitials = (firstName, lastName) => {
  return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
};

const getAvatarColor = (initials) => {
  const colors = [
    'bg-gradient-to-br from-pink-500 to-rose-500',
    'bg-gradient-to-br from-blue-500 to-cyan-500',
    'bg-gradient-to-br from-green-500 to-emerald-500',
    'bg-gradient-to-br from-purple-500 to-violet-500',
    'bg-gradient-to-br from-yellow-500 to-amber-500'
  ];
  const index = initials.charCodeAt(0) % colors.length;
  return colors[index];
};

const AddWithShukranId = ({ onBack, onSwitchToPhone, onProceed }) => {
  const [includeInGroupTips, setIncludeInGroupTips] = useState(true);
  const [searchResults, setSearchResults] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const searchAccount = useSearchAccount();
  const { entityDepartments } = useTipJarLocalState();
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const activeDepartments = entityDepartments?.filter(dept => dept.active) || [];

  const handleSearchResults = async (searchParams = null) => {
    try {
      const response = await searchAccount.mutateAsync(searchParams);
      const data = response?.data;
      
      if (data) {
        setSearchResults(data.results);
        setNextPage(data.next);
        setPrevPage(data.previous);
  
      } else {
        toast.error("No results found");
      }
    } catch (error) {
      toast.error("Search failed");
      setSearchResults(null);
    }
  };

  // Use the custom hook
  const { searchTerm, setSearchTerm, isSearching } = useDebounceSearch(handleSearchResults);

  const handleProceed = () => {
    if (!selectedUser) {
      toast.error("Please select a user first");
      return;
    }
    
    const memberData = {
      user: selectedUser,
      department_id: selectedDepartment,
      can_join_entity_jar: includeInGroupTips
    };
    
    onProceed(memberData);
  };

  // Extract page URL from full URL
  const getPageUrl = (fullUrl) => {
    if (!fullUrl) return null;
    try {
      const url = new URL(fullUrl);
      return `${url.pathname}${url.search}`;
    } catch (e) {
      return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white px-4 pt-4">
      <div className='w-full flex flex-row items-center gap-6 mb-6'>
        <button 
          onClick={onBack}
          className="flex items-center text-gray-600 w-fit"
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <p className='text-lg font-semibold'>Add with Shukran Id</p>
      </div>

      {selectedUser && (
        <div className="mb-4">
          <div className="flex flex-wrap gap-2">
            <div className="bg-gray-100 px-3 py-1 rounded-full flex items-center gap-2">
              <span>{`${selectedUser.first_name} ${selectedUser.last_name}`}</span>
              <button 
                onClick={() => setSelectedUser(null)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
          </div>
        </div>
      )}
      
      <div className="space-y-4 mb-6">
        <div className="relative">
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by name or Shukran ID"
            className="h-12"
          />
          {isSearching && (
            <div className="absolute right-3 top-1/2 -translate-y-1/2">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-400" />
            </div>
          )}
        </div>

        <Select
          value={selectedDepartment}
          onValueChange={setSelectedDepartment}
        >
          <SelectTrigger className="h-12">
            <SelectValue placeholder="Select department" />
          </SelectTrigger>
          <SelectContent>
            {activeDepartments.map((department) => (
              <SelectItem 
                key={department.id} 
                value={department.id}
              >
                {department.name} ({department.distribution_points} points)
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="groupTips"
            checked={includeInGroupTips}
            onCheckedChange={setIncludeInGroupTips}
          />
          <label htmlFor="groupTips" className="text-sm text-gray-600">
            Include member in group tips
          </label>
        </div>

        {searchResults && searchResults.length > 0 && (
          <div className="space-y-2">
            <p>Results</p>
            {searchResults.map((user) => {
              const initials = getInitials(user.first_name, user.last_name);
              return (
                <div 
                  key={user.id}
                  className="flex items-center justify-between p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                  onClick={() => setSelectedUser(user)}
                >
                  <div className="flex items-center gap-3">
                    <div className={`w-10 h-10 rounded-full flex items-center justify-center text-white ${getAvatarColor(initials)}`}>
                      {initials}
                    </div>
                    <div>
                      <p className="font-medium">{`${user.first_name} ${user.last_name}`}</p>
                      <p className="text-sm text-gray-500">{user.shukran_id}</p>
                    </div>
                  </div>
                  <Checkbox
                    checked={selectedUser?.id === user.id}
                    className="h-5 w-5"
                  />
                </div>
              );
            })}

            <div className="flex justify-between items-center mt-4">
              <Button
                onClick={() => handleSearchResults(getPageUrl(prevPage))}
                disabled={!prevPage || isSearching}
                variant="outline"
                size="sm"
              >
                <ChevronLeftIcon className="h-4 w-4" />
                Previous
              </Button>
              <Button
                onClick={() => handleSearchResults(getPageUrl(nextPage))}
                disabled={!nextPage || isSearching}
                variant="outline"
                size="sm"
              >
                Next
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="flex items-center gap-2 mb-6">
        <span className="text-gray-500">OR</span>
        <button
          onClick={onSwitchToPhone}
          className="px-3 py-1 rounded-md bg-[#50A2A7] text-white text-sm"
        >
          add with phone number
        </button>
      </div>

      <Button
        onClick={handleProceed}
        disabled={isSearching || !selectedUser}
        className="w-full bg-[#F2C773] text-white h-14 rounded-full hover:bg-[#F2C773]/90 disabled:opacity-50"
      >
        {isSearching ? 'SEARCHING...' : 'PROCEED'}
      </Button>
    </div>
  );
};

export default AddWithShukranId;