import React from 'react';
import { CirclePlus } from 'lucide-react';
import AddButton from '../../globals/AddButton';
import AddWithPhone from './AddWithPhone';
import AddWithShukranId from './AddWithShukranId';
import ConfirmDetails from './ConfirmDetails';
import { useState, useEffect } from 'react';
import { useTipJarLocalState } from '../../Tipjar/state/local-state/useTipjarLocalState';
import {toast} from 'sonner'
import { useRegistrationLocalState } from '../state/local-state/useRegistrationLocalState';
import { useAddMemebersToEntity } from '../../Tipjar/state/server-state/useTipJarServerState';
import { useFetchEntityMembers } from '../../Tipjar/state/server-state/useTipJarServerState';
import user from "../../../assets//Icons/Dashboard/Members.png"
import WelcomeDialog from './WelcomeDialog';

const IndividualsSetup = ({ onViewChange }) => {
  const [currentView, setCurrentView] = useState('main');
  const [members, setMembers] = useState([]);

  const [shukranMembers, setShukranMembers] = useState([]);
  const [phoneMembers, setPhoneMembers] = useState([]);
  const {hasShownIndividualSetup,setHasShownIndividualSetup }=useRegistrationLocalState()
  const {activeEntity,setEntityMembers}=useTipJarLocalState()
  const addMultipleMembersToEntity = useAddMemebersToEntity();
  const isAddingMultipleMembersToEntity = addMultipleMembersToEntity.isLoading;

   const {
      data: entityMembers,
      isLoading: isLoadingEntityMembers,
      error: entityMembersError,
      refetch,
    } = useFetchEntityMembers(activeEntity?.id);

     useEffect(() => {
        if (entityMembers?.data) {
          setEntityMembers(entityMembers.data);
          setMembers(entityMembers.data)
        }
      }, [entityMembers]);


  // Notify parent component when view changes
  useEffect(() => {
    onViewChange(currentView);
  }, [currentView, onViewChange]);

   // Check for duplicates in both arrays
   const checkForDuplicates = (memberData, isShukran) => {
    if (isShukran) {
      // Check if Shukran ID already exists
      const isDuplicateShukran = shukranMembers.some(
        member => member.user.shukran_id === memberData.user.shukran_id
      );
      
      // Also check if the same phone number exists in phoneMembers
      const isDuplicatePhone = phoneMembers.some(
        member => member.phone === memberData.user.phone
      );

      return isDuplicateShukran || isDuplicatePhone;
    } else {
      // Check if phone number already exists in phoneMembers
      const isDuplicatePhone = phoneMembers.some(
        member => member.phone === memberData.phone
      );

      // Also check if the same phone exists in shukranMembers
      const isDuplicateShukran = shukranMembers.some(
        member => member.user.phone === memberData.phone
      );

      return isDuplicatePhone || isDuplicateShukran;
    }
  };

  // Handler for phone number additions
  const handlePhoneMemberAdd = (memberData) => {
    if (checkForDuplicates(memberData, false)) {
      toast.error("This member has already been added");
      return;
    }
    setPhoneMembers(prev => [...prev, memberData]);
    setCurrentView('confirm');
  };

  // Handler for Shukran ID additions
  const handleShukranMemberAdd = (memberData) => {
    if (checkForDuplicates(memberData, true)) {
      toast.error("This member has already been added");
      return;
    }
    setShukranMembers(prev => [...prev, memberData]);
    setCurrentView('confirm');
  };

  // Allow removing members in ConfirmDetails
  const handleRemoveMember = (memberId, isShukran) => {
    if (isShukran) {
      setShukranMembers(prev => 
        prev.filter(member => member.user.id !== memberId)
      );
    } else {
      setPhoneMembers(prev => 
        prev.filter(member => member.phone !== memberId)
      );
    }
  };

   // Handler for department updates
   const handleUpdateMemberDepartment = (memberId, isShukran, departmentId) => {
    if (isShukran) {
      setShukranMembers(prev => prev.map(member => 
        member.user.id === memberId 
          ? { ...member, department_id: departmentId }
          : member
      ));
    } else {
      setPhoneMembers(prev => prev.map(member => 
        member.phone === memberId 
          ? { ...member, department_id: departmentId }
          : member
      ));
    }
  };
   // Generate final payload
   const generatePayload = (entityId) => {
    return {
      entity_id: entityId,
      users_ids: shukranMembers.map(member => ({
        user_id: member.user.id,
        department_id: member.department_id,
        can_join_entity_jar: member.can_join_entity_jar
      })),
      express_members: phoneMembers.map(member => ({
        first_name: member.first_name,
        last_name: member.last_name,
        phone: member.phone,
        department_id: member.department_id,
        can_join_entity_jar: member.can_join_entity_jar
      }))
    };
  };

  const handleConfirm =async () => {
    const payload = generatePayload(activeEntity?.id);
    // Submit payload to your API
    console.log(payload);
    try {
          await addMultipleMembersToEntity.mutateAsync(payload);
          toast.success(`Member(s) added successfully`);
          handleBack();
        } catch (error) {
          toast.error("Failed to add members");
        }
  };
  const handleBack = () => {
    switch (currentView) {
      case 'phone':
      case 'shukran':
        setCurrentView('main');
        break;
      case 'confirm':
        setCurrentView('main');
        break;
      default:
        break;
    }
  };

  const renderMembersList = (members) => {
    if (members.length === 0) {
      return (
        <div className="mt-6 empty">
          <img src={user} alt="No members" />
          <p className="w-40 mt-6 text-center desctxt">
            No members available. Add new
          </p>
        </div>
      );
    }

    return members.map((member) => (
      <div
        key={member.id}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          className="border-2 border-[#F5F5F5] w-full min-h-[67px] mb-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="w-1/2 px-2"
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "15px",
                backgroundColor: "#50A2A7",
              }}
              className="flex items-center justify-center text-white text-sm"
            >
              {`${member?.user?.first_name[0]}${member?.user?.last_name[0]}`}
            </div>
            <div className="ml-2">
              <p
                style={{ color: "#404B5A", fontSize: "14px" }}
                className="font-semibold"
              >
                {`${member?.user?.first_name} ${member?.user?.last_name}`}
              </p>
              <div className="flex flex-row items-center gap-1 w-full">
                <p style={{ color: "#929FB1", fontSize: "10px" }}>
                  {member?.user?.shukran_id}
                </p>
                <p style={{ fontSize: "11px" }} className="font-semibold text-slate-600">
                  - {member?.department?.name}
                </p>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    ));
  };


  const renderView = () => {
    switch (currentView) {
      case 'phone':
        return (
          <AddWithPhone
          onBack={handleBack}
          onSwitchToShukran={() => setCurrentView('shukran')}
          onMemberAdd={handlePhoneMemberAdd}
        />
        );
      case 'shukran':
        return (
          <AddWithShukranId
          onBack={handleBack}
          onSwitchToPhone={() => setCurrentView('phone')}
          onProceed={handleShukranMemberAdd}
        />
        );
      case 'confirm':
        return (
          <ConfirmDetails
          onBack={handleBack}
          onAddNew={() => setCurrentView('phone')}
          onConfirm={handleConfirm}
          shukranMembers={shukranMembers}
          phoneMembers={phoneMembers}
          onUpdateMemberDepartment={handleUpdateMemberDepartment}
          onRemoveMember={handleRemoveMember}
          isAddingMultipleMembersToEntity={isAddingMultipleMembersToEntity}
        />
        );
      default:
        return (
          <div className="px-4 flex-1">
            <AddButton 
              title="New Member" 
              onClick={() => setCurrentView('phone')} 
            />
            <WelcomeDialog 
              open={hasShownIndividualSetup}
              onOpenChange={setHasShownIndividualSetup}
              title="Add Your Team Members"
              description="Now, let's add the people who make your business great. You can add your team members individually and assign them to their respective departments."
              message="Click on 'New Member' to start adding team members. You can add them using either their phone numbers or their Shukran IDs if they already have accounts."
              />
            <div className="flex items-center mb-4 mt-6">
              <h2 className="text-gray-700">Members (0)</h2>
            </div>
            {renderMembersList(members)}
            

            {/* <div className="flex flex-col items-center justify-center py-12">
              <div className="w-16 h-16 bg-gray-100 rounded-full mb-4 flex items-center justify-center">
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-400">
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                  <circle cx="9" cy="7" r="4" />
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                  <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>
              </div>
              <p className="text-gray-500 text-center">Added individuals will appear here</p>
            </div> */}
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {renderView()}
    </div>
  );
};

export default IndividualsSetup;