
import {
    useQuery,
    useQueryClient,
    UseMutationResult,
    UseQueryResult,
    useMutation,
  } from "@tanstack/react-query";
  import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { useFirstTimeTipperQueryTypes } from "./useFirstTimeTipperQueryTypes";
import axios from "axios";
import { BASE_URL } from "../../../../constants/network";


type User = {
    first_name: string;
    last_name: string;
    phone: string;
    password: string;
    user_type: "SERVCICE WORKER"; // If this value is fixed
    is_sacco: boolean; // This should be a boolean
    sacco_type: "new",
    platform: string,
    device: string,
};

export const signUpUser=async( payload:User ):Promise<any>=>{

    const apiUrl= `${BASE_URL}accounts/auth/signup/`
    try {
        const response = await axios.post(apiUrl, payload);
        return response
      } catch (error:any) {
        console.log("SIGN ERROR ", error?.response?.statusText)
        throw new Error(error?.response?.statusText||"An unexpected error occurred");
      }
}

export const useSignUpUser=()=>{
    return useMutation({
        mutationKey:[useFirstTimeTipperQueryTypes.SIGN_UP_USER],
        mutationFn:(payload:User)=>signUpUser(payload)
    })
}

type UserCredentials = {
    phone: string;
    token: string;
    nonce?:string
};

const requestOTP=async($http: AxiosInstance,payload:UserCredentials )=>{

    const apiUrl=constructApiUrl("/accounts/auth/request_otp/")
    try{
        const response=await $http.post(apiUrl, payload)
        return response
    }catch(error){
        console.log("SEND OTP ERROR")
        throw new Error("Failed to request OTP")
    }
}

export const useRequestOTP=()=>{
    const $http = useAxios();
    return useMutation({
        mutationKey:[useFirstTimeTipperQueryTypes.REQUEST_OTP],
        mutationFn:(payload:UserCredentials)=>requestOTP($http, payload)
    })
}

const submitOTP=async($http: AxiosInstance,payload:UserCredentials )=>{

    const apiUrl=constructApiUrl("/accounts/auth/submit_otp/")
    
    try{
        const response=await $http.post(apiUrl, payload)
        return response
    }catch(error){
        console.log("SEND OTP ERROR")
        throw new Error("Failed to submit OTP")
    }
}

export const useSubmitOTP=()=>{
    const $http = useAxios();
    return useMutation({
        mutationKey:[useFirstTimeTipperQueryTypes.SUBMIT_OTP],
        mutationFn:(payload:UserCredentials)=>submitOTP($http, payload)
    })
}

const fetchWithdrawBalance = async ($http: AxiosInstance):Promise<any> => {
    const apiUrl=constructApiUrl(`/accounts/auth/balance/`)
    try{
      const response = await $http.get(apiUrl);
      return response.data;
    }catch(error){
      console.log(error);
    }
  }

  export const useGetWithdrawBalance=()=>{
    const $http = useAxios();
    return useQuery({
      queryKey: [useFirstTimeTipperQueryTypes.GET_WITHDRAW_BALANCE],
      queryFn: () => fetchWithdrawBalance($http),
    })
  }

  interface SaccoActivationPayload {
    national_id_passport: string;
    phone_number: string;
    first_name: string;
    surname: string;
    email: string;
    date_of_birth: string;
    kyc_stage: number;
}

export const activateSacco = async ($http: AxiosInstance,payload: SaccoActivationPayload): Promise<any> => {
    // const apiUrl = `${BASE_URL}sacco/activate-sacco/`
    const apiUrl=constructApiUrl("/sacco/activate-sacco/")
    try {
        const response = await $http.post(apiUrl, payload);
        return response;
    } catch (error: any) {
        console.log("SACCO ACTIVATION ERROR", error?.response?.statusText);
        throw new Error(error?.response?.statusText || "An unexpected error occurred");
    }
}

export const useActivateSacco = () => {
    const $http = useAxios();
    return useMutation({
        mutationKey: [useFirstTimeTipperQueryTypes.ACTIVATE_SACCO],
        mutationFn: (payload: SaccoActivationPayload) => activateSacco($http,payload)
    });
}

const withdrawFunds = async ($http: AxiosInstance, payload: any):Promise<any> => {
    const apiUrl=constructApiUrl(`/transactions/withdraw/mpesa/`)
    console.log("WITHDRAW FUNDS PAYLOAD ----> ", payload)
    try{
        const response = await $http.post(apiUrl, payload);
        console.log("WITHDRAW FUNDS RESPONSE ----> ", response)
      
        return response.data;
    }catch(error){  
        console.log("WITHDRAW FUNDS ERROR ----> ", error)
        throw new Error("An error occurred while withdrawing funds");
    }
  }

  export const useWithdrawFunds=()=>{
    const $http = useAxios();
    return useMutation({
      mutationKey: [useFirstTimeTipperQueryTypes.WITHDRAW_FUNDS],
      mutationFn: (payload: any) => withdrawFunds($http, payload),
    })
  }


  // Interface for self-tip request
interface SelfTipRequest {
    amount: string;
}

// Function to get processing balance
export const getProcessingBalance = async ($http: AxiosInstance): Promise<any> => {
    // const apiUrl = `${BASE_URL}transactions/balance/get-processing-balance`
    const apiUrl=constructApiUrl("/transactions/balance/get-processing-balance")
    try {
        const response = await $http.get(apiUrl);
        return response;
    } catch (error: any) {
        console.log("GET PROCESSING BALANCE ERROR", error?.response?.statusText);
        throw new Error(error?.response?.statusText || "An unexpected error occurred");
    }
}

// Hook for getting processing balance
export const useGetProcessingBalance = () => {
    const $http = useAxios();
    return useQuery({
        queryKey: [useFirstTimeTipperQueryTypes.GET_PROCESSING_BALANCE],
        queryFn: () => getProcessingBalance($http)
    });
}

// Function to request MPesa self-tip
export const requestMpesaSelfTip = async ($http: AxiosInstance,payload: SelfTipRequest): Promise<any> => {
    // const apiUrl = `${BASE_URL}transactions/self-tip-advance/mpesa/`
    const apiUrl=constructApiUrl("/transactions/self-tip-advance/mpesa/")
    try {
        const response = await $http.post(apiUrl, payload);
        return response;
    } catch (error: any) {
        console.log("MPESA SELF TIP ERROR", error?.response?.statusText);
        throw new Error(error?.response?.statusText || "An unexpected error occurred");
    }
}

// Hook for MPesa self-tip
export const useMpesaSelfTip = () => {
    const $http = useAxios();
    return useMutation({
        mutationKey: [useFirstTimeTipperQueryTypes.REQUEST_MPESA_SELF_TIP],
        mutationFn: (payload: SelfTipRequest) => requestMpesaSelfTip($http,payload)
    });
}


const fetchProfile=($http: AxiosInstance):Promise<any>=>{
    const apiUrl=constructApiUrl("/accounts/auth/profile/")
    try{
        const response = $http.get(apiUrl);
        return response;
    }catch(error:any){
        console.log("FETCH PROFILE ERROR", error?.response?.statusText);
        throw new Error(error?.response?.statusText || "An unexpected error occurred");
    }
}

export const useFetchProfile=()=>{
    const $http = useAxios();
    return useQuery({
        queryKey: [useFirstTimeTipperQueryTypes.FETCH_PROFILE],
        queryFn: () => fetchProfile($http)
    });
}

