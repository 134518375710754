import { create, StateCreator } from "zustand";

interface ShowMpesaSlice {
  showMpesa: boolean;
  setShowMpesa: (show: boolean) => void;
}

interface RecepientInfo{
  shukranId: string|null;
  setShukranId: (shukranId: string) => void;
}

interface ContributionStatus {
  contributionStatus: boolean;
  setContributionStatus: (status: boolean) => void;
}

// Create the slice for showMpesa
const createShowMpesaSlice: StateCreator<ShowMpesaSlice> = (set) => ({
  showMpesa: false,
  setShowMpesa: (show: boolean) => set({ showMpesa: show }),
});

const createRecipientInfoSlice: StateCreator<RecepientInfo> = (set) => ({
  shukranId: null,
  setShukranId: (shukranId: string) => set({ shukranId }),
});

const createContributionStatusSlice: StateCreator<ContributionStatus> = (set) => ({
  contributionStatus: false,
  setContributionStatus: (status: boolean) => set({ contributionStatus: status }),
})

// Create the store without persistence
export const useTippingLocalState = create<ShowMpesaSlice&RecepientInfo&ContributionStatus>()((...a) => ({
  ...createShowMpesaSlice(...a),
  ...createRecipientInfoSlice(...a),
  ...createContributionStatusSlice(...a),
}));