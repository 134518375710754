import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShukranTagLine from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../components-shadcn/ui/input";
import BackButton from '../globals/BackButton';
import { useFirstTimeTipState } from './state/local-state/useFirstTimeTipLocalState';

const UserDetailsInitial = () => {
  const navigate = useNavigate();
  const { 
    userData,
    firstName,
    lastName,
    firstNameError,
    lastNameError,
    setFirstName,
    setLastName
  } = useFirstTimeTipState();

  // Redirect if no phone number
  useEffect(() => {
    if (!userData?.phone) {
      navigate('/first-time-tip');
    }
  }, [userData?.phone, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'firstName') {
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastName(value);
    }
  };

  const isFormValid = firstName.length >= 2 && lastName.length >= 2;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      navigate('/confirm-password-initial');
    }
  };

  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full px-4">
        <BackButton onPress={()=>navigate(-1)}/>
      </div>

      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranTagLine} alt="Shukran Logo" className="w-28" />
      </div>

      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <h2 className="text-[#50A2A7] text-xl font-medium mb-6">
          Activate your account to access Shukran
        </h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <label className="text-gray-600">First name</label>
            <Input
              name="firstName"
              value={firstName}
              onChange={handleChange}
              placeholder="Eg. John"
              className={`h-12 border-gray-200 rounded-lg focus:border-[#50A2A7] focus:ring-[#50A2A7] ${
                firstNameError ? 'border-red-500' : ''
              }`}
            />
            {firstNameError && (
              <p className="text-red-500 text-sm mt-1">{firstNameError}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-gray-600">Last name</label>
            <Input
              name="lastName"
              value={lastName}
              onChange={handleChange}
              placeholder="Eg. Doe"
              className={`h-12 border-gray-200 rounded-lg focus:border-[#50A2A7] focus:ring-[#50A2A7] ${
                lastNameError ? 'border-red-500' : ''
              }`}
            />
            {lastNameError && (
              <p className="text-red-500 text-sm mt-1">{lastNameError}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-gray-600">Phone number</label>
            <Input
              name="phoneNumber"
              value={userData?.phone || ''}
              readOnly
              className="h-12 border-gray-200 rounded-lg bg-gray-50"
            />
          </div>

          <button
            type="submit"
            disabled={!isFormValid}
            className={`w-full text-white font-medium py-4 rounded-full mt-8 transition-colors ${
              isFormValid
                ? 'bg-[#F2C773] hover:bg-[#F2C773]/90'
                : 'bg-gray-300 cursor-not-allowed'
            }`}
          >
            VERIFY NUMBER
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserDetailsInitial;