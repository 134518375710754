import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ShukranTagLine from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useFirstTimeTipState } from './state/local-state/useFirstTimeTipLocalState';

const ActivateAccountInitial = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get('phone');
  
  const { setUserData } = useFirstTimeTipState();

  const handleActivate = () => {
    // Save phone to state
    setUserData({ phone });
    // Navigate to user details
    navigate('/user-details-initial');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-white px-6 justify-between">
      <div className='w-full flex flex-col items-center gap-2'>
        <div className="w-full mt-8 mb-12">
          <img src={ShukranTagLine} alt="Shukran" className="w-32 mx-auto" />
        </div>

        <div className="w-full flex flex-col items-center">
          <h1 className="text-[#50A2A7] text-2xl font-medium mb-6 text-center">
            We found an account linked to these details
          </h1>

          <div className="w-full bg-[#FFF9ED] rounded-lg p-4 mb-6">
            <div className="space-y-2">
              <p className="text-gray-800">
                <span className="font-medium">Phone : </span>
                {phone}
              </p>
              <p className="text-gray-800">
                <span className="font-medium">Account status : </span>
                Inactive
              </p>
            </div>
          </div>

          <p className="text-gray-800 mb-6">
            Continue to activate this account?
          </p>
        </div>

        <div className="w-full space-y-3">
          <button 
            onClick={handleActivate}
            className="w-full bg-[#F2C773] text-white py-4 rounded-full font-medium hover:bg-[#F2C773]/90 transition-colors"
          >
            YES, ACTIVATE
          </button>
          
          <button 
            onClick={handleGoBack}
            className="w-full bg-gray-100 text-gray-600 py-4 rounded-full font-medium hover:bg-gray-200 transition-colors"
          >
            NO, GO BACK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccountInitial;