import React from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogAction,
} from '../../../components-shadcn/ui/alert-dialog';
import { Layout, Users } from 'lucide-react';

const WelcomeDialog = ({ open, onOpenChange, title, description, message }) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="max-w-md">
        <AlertDialogHeader>
          <div className="flex justify-center mb-6">
            <div className="bg-[#50A2A7] p-4 rounded-full">
              <Layout className="h-8 w-8 text-white" />
            </div>
          </div>
          <AlertDialogTitle className="text-center text-xl text-[#50A2A7] mb-2">
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription className="text-center text-gray-600">
            {description}
            <div className="mt-4 bg-[#FFF9ED] p-4 rounded-lg flex items-start gap-3">
              <Users className="h-5 w-5 text-[#F2C773] mt-1 flex-shrink-0" />
              <p className="text-sm text-left">
               {message}
              </p>
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="sm:justify-center">
          <AlertDialogAction className="bg-[#F2C773] hover:bg-[#F2C773]/90 text-white w-full sm:w-32 rounded-full">
            Got it
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default WelcomeDialog;