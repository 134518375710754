import React, { useState, useRef, useEffect } from "react";
import ArrowButtonNext from "../../assets/Icons/Onboarding/ArrowButtonNext.svg";
import ExclamationIcon from "../../assets/Icons/Tipping/exclamation_green.svg";
import EmojiOne from "../../assets/Icons/Tipping/emoji_one.svg";
import EmojiTwo from "../../assets/Icons/Tipping/emoji_two.svg";
import EmojiThree from "../../assets/Icons/Tipping/emoji_three.svg";
import EmojiFour from "../../assets/Icons/Tipping/emoji_four.svg";
import EmojiFive from "../../assets/Icons/Tipping/emoji_five.svg";
import EmojiSix from "../../assets/Icons/Tipping/emoji_six.svg";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { Loader2 } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import Popup from "../popups/Popup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ArrowRight } from "lucide-react";
import { testUrl } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import PrimaryGlobalText from "../globals/PrimaryGlobalText.jsx";
import Desc from "../globals/Desc.jsx";
import Button from "./components/Button.jsx";
import usa from "../../assets/Icons/flags/usa.png";
import { toast as sonnerToast } from "sonner";
import kenya from "../../assets/Icons/flags/kenyan.png";
import BackButton from "../globals/BackButton.jsx";
import userStore from "../../store/user.store";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useTippingLocalState } from "./state/local-state/useTippingLocalState";
import { Button as ShadcnButton } from "../../components-shadcn/ui/button";
import { Input } from "../../components-shadcn/ui/input";
export default function TipAmount() {
  const [amounts] = useState([1000, 500, 200]);
  const [kinetic] = useState([20000, 10000, 5000]);
  const [chezsonia] = useState([2000, 1000, 500]);
  const [cabanas] = useState([2000, 1500, 1000]);
  const [emaiyan] = useState([10000, 5000, 2000]);
  const [launchpad] = useState([600, 350]);
  const [customAmountError, setCustomAmountError] = useState();
  const [isInsufficient, setIsInsufficient] = useState(false);
  const [emojiStage, setEmojiStage] = useState(1);
  const [tipperTitle, setTipperTitle]= useState(null);
  const { defaultEntityJAR, setDefaultEntityJAR } = useTipJarLocalState();
  const [isFocused, setIsFocused] = useState(false);
  const {setShukranId, setContributionStatus, contributionStatus}=useTippingLocalState();
  // state for popup
  const [isClicked, setIsClicked] = useState(false);
  const [queryId, setQueryId] = useState("");
  const [queryFromPath, setQueryFromPath] = useState("");
  const [nameFromPath, setNameFromPath] = useState("");
  const [shukranFromPath, setShukranFromPath] = useState("");
  const [queryName, setQueryName] = useState("");
  const [customAmount, setCustomAmount] = useState();
  const receiverName = useSelector((state) => state.tipData.receiverName);
  const receiverLastName = useSelector(
    (state) => state.tipData.receiverLastName
  );
  const tipAmount = useSelector((state) => state.tipData.tipAmount);
  const balanceData = useSelector((state) => state.balance);
  const tipData = useSelector((state) => state.tipData);
  const userInfo = useSelector((state) => state.balance.userInfo);

  const { user } = userStore();

  const balance = balanceData.balance;
  const nonShukranId = useSelector((state) => state.tipData.nonShukranId);
  const [showSessionExpired, setShowSessionExpired] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");
  const dispatch = useDispatch();
  const { addTipAmount, addQueryShukranId, addCurrency } = bindActionCreators(
    actions,
    dispatch
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm({});

  const navigate = useNavigate();


  console.log("CUSTOM AMOUNT ", customAmount)

  const [searchParams] = useSearchParams();
  const locationPath = useLocation();
  const [selectedCurrency, setSelectedCurrency] = useState("KSH");
  const [isLoadingEntity, setIsLoadingEntity] = useState(false);
  const [isContribution, setIsContribution] = useState(false);

  useEffect(() => {
    let paramShukranId = searchParams.get("shukranId");
    setShukranId(paramShukranId);
    let redirect = searchParams.get("redirect");
    setRedirectTo(redirect);
    let paramName = searchParams.get("name");
    const title=searchParams.get("title");
    setTipperTitle(title);
    if(title&&paramShukranId==="SF1241"){
        setContributionStatus(true);
    }else{
      setContributionStatus(false)
    }

    setQueryId(paramShukranId);
    if (locationPath?.pathname?.split("/").length > 0) {
      setQueryFromPath(locationPath?.pathname?.split("/")[1]);
    }
    setQueryName(paramName);
    return () => {};
  }, [locationPath?.pathname, searchParams]);
  useEffect(() => {
    if (
      queryFromPath?.split("").length > 0 &&
      locationPath?.pathname?.split("/")[1] !== "tip"
    ) {
      setIsLoadingEntity(true);
      balanceData.errorMessage = "";
      axios.defaults.headers.common["XAT"] = `U`;
      axios.defaults.headers.common["X-IDT"] = `A`;
      axios
        ?.get(`${testUrl}accounts/auth/search/?shukran_id=${queryFromPath}`)
        .then((res) => {
          setNameFromPath(
            `${res.data?.results[0].first_name} ${res.data?.results[0].last_name}`
          );
          setShukranFromPath(`${res.data?.results[0]?.shukran_id}`);
          // if (res?.data.results[0]?.user_type === "TIP_JAR") {
          if (res?.data.results[0]?.user_type === "ENTITY_JAR") {
            axios
              ?.get(
                `${testUrl}tipjar/entity/search?shukran_id=${queryFromPath}`
              )
              .then((res) => {
                const resData = res.data;
                setDefaultEntityJAR(resData);
                localStorage.setItem("entityToTip", JSON.stringify(resData));
                setIsLoadingEntity(false);
                navigate("/tip-groups");
              })
              .catch((err) => {
                setIsLoadingEntity(false);
                sonnerToast.error("Failed to fetch entity");
                console.log("FAILED TO FETCH DEF JAR FOR USER ", err);
              });
          } else {
            setIsLoadingEntity(false);
          }
        })
        .catch((err) => {
          setIsLoadingEntity(false);
        });
    }
  }, [queryFromPath]);
  useEffect(() => {
    if (tipAmount) {
      setValue("customAmount", tipAmount);
    }
    if (tipData.currency) {
      setSelectedCurrency(tipData.currency);
    }
    return () => {};
  }, []);

  useEffect(() => {
    const subscription = watch((value, config) => {
      let customAmt = Number(value.customAmount);

      if (config.name === "customAmount" && value.customAmount.length > 0) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
      if (config.name === "customAmount") {
        if ((customAmt >= 10) & (customAmt <= 100)) {
          setEmojiStage(1);
        } else if ((customAmt >= 101) & (customAmt <= 200)) {
          setEmojiStage(2);
        } else if ((customAmt >= 201) & (customAmt <= 300)) {
          setEmojiStage(3);
        } else if ((customAmt >= 301) & (customAmt <= 500)) {
          setEmojiStage(4);
        } else if ((customAmt >= 501) & (customAmt <= 1000)) {
          setEmojiStage(5);
        } else if (customAmt >= 1000) {
          setEmojiStage(6);
        } else {
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = (data) => {
    
    if (data.customAmount >= 1 || selectedCurrency === "USD") {
      addTipAmount(data.customAmount);
      if (queryId) {
        addQueryShukranId({
          query_shukran_id: queryId,
          query_name: queryName,
          currency: selectedCurrency,
        });
        if (queryId === "LP1261") {
          navigate(
            `/pay?shukranId=LP1261&name=LaunchPad+Innovation+Campus&message=How+do+you+want+to+pay+Ksh+${data.customAmount}&amount=${data.customAmount}`
          );
        } else {
          if (redirectTo === "bill") {
            navigate(`/paymentOpt?redirect=bill`);
          } else {
            navigate("/paymentOpt");
          }
        }
      } else if (
        nameFromPath?.split("")?.length > 0 &&
        shukranFromPath?.split("")?.length > 0
      ) {
        addQueryShukranId({
          query_shukran_id: shukranFromPath,
          query_name: nameFromPath,
          currency: selectedCurrency,
        });
        if (redirectTo === "bill") {
          navigate(`/paymentOpt?redirect=bill`);
        } else {
          navigate("/paymentOpt");
        }
      } else {
        if (redirectTo === "bill") {
          navigate(`/paymentOpt?redirect=bill`);
        } else {
          navigate("/paymentOpt");
        }
        addCurrency(selectedCurrency);
      }
    } else {
      toast.warning("Amount should be more than 50 KES!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleSetAmount = (amount) => {
    if (amount > balance) {
      setIsInsufficient(true);
    }
    let tipAmount;
    if (selectedCurrency === "USD") {
      tipAmount = (amount / 100).toString();
    } else {
      tipAmount = amount.toString();
    }

    addTipAmount(tipAmount);
    if (queryId) {
      addQueryShukranId({
        query_shukran_id: queryId,
        query_name: queryName,
        currency: selectedCurrency,
      });
      if (queryId === "LP1261") {
        navigate(
          `/pay?shukranId=LP1261&name=LaunchPad+Innovation+Campus&message=How+do+you+want+to+pay+Ksh+${tipAmount}&amount=${tipAmount}`
        );
      } else {
        if (redirectTo === "bill") {
          navigate(`/paymentOpt?redirect=bill`);
        } else {
          navigate("/paymentOpt");
        }
      }
    } else if (
      nameFromPath?.split("")?.length > 0 &&
      shukranFromPath?.split("")?.length > 0
    ) {
      addQueryShukranId({
        query_shukran_id: shukranFromPath,
        query_name: nameFromPath,
        currency: selectedCurrency,
      });

      if (redirectTo === "bill") {
        navigate(`/paymentOpt?redirect=bill`);
      } else {
        navigate("/paymentOpt");
      }
    } else {
      if (redirectTo === "bill") {
        navigate(`/paymentOpt?redirect=bill`);
      } else {
        navigate("/paymentOpt");
      }
      addCurrency(selectedCurrency);
    }
  };

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    isLoggedIn = JSON.parse(isLoggedIn);
    if (
      balanceData.errorMessage === 403 &&
      isLoggedIn &&
      queryId !== "LP1261" &&
      queryId !== "CL2428" &&
      queryId !== "CS1331"
    ) {
      //navigate("/login");
      // alert("hjhjsbjhdsbhsd");
      setShowSessionExpired(true);
    }
    // if (
    //   balanceData.errorMessage === 403 &&
    //   !isLoggedIn &&
    //   queryId !== "LP1261" &&
    //   queryId !== "CL2428" &&
    //   queryId !== "CS1331"
    // ) {
    //   navigate("/registration");
    // }

    return () => {
      // dispatch(balanceActions.tippingSuccess({}));
      // dispatch(searchActions.searchLoadSuccess([]));
      // addShukranId({});
      // addNonShukranId({})
    };
  }, [balanceData, navigate]);

  const SessionExpired = () => (
    <div className="my-auto px-4 drop-shadow-md bg-white w-4/5 pt-10 flex flex-col items-center relative pb-4 rounded-3xl z-50">
      {/* <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          // dispatch(balanceActions.checkTippingError(""));
          // dispatch(balanceActions.tippingError(""));
        }}
      >
        X
      </button> */}
      <img src={NotFoundIcon} alt="Not found icon" className="w-20 h-20" />
      <PrimaryGlobalText
        text="Your session has expired"
        fontSize={"20px"}
        fontWeight={"800"}
      />
      <Desc
        text="To use your saved info please log back in"
        fontSize="15px"
        fontWeight={"700"}
      />
      <div className="mt-4 flex flex-row items-center justify-evenly w-full">
        <Button
          text="Login"
          color={"accent"}
          txtColor={"white"}
          onPress={() => navigate("/login")}
        />
        <Button
          text="Continue"
          color={"primary-pwa"}
          txtColor={"white"}
          onPress={() => {
            localStorage.setItem("isLoggedIn", JSON.stringify(false));
            setShowSessionExpired(false);
          }}
        />
      </div>
    </div>
  );

  if (isLoadingEntity) {
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center">
        <div className="w-full flex flex-col gap-2 items-center">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          <p>Please wait ...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${
        isInsufficient ? "relative items-center" : ""
      } w-full flex flex-col`}
    >
      <div
        className={`${
          showSessionExpired ? "blur-sm" : ""
        } flex flex-col px-3  w-full h-screen`}
      >
        <div
          className={`
          ${isInsufficient ? "blur-sm" : ""}
          flex flex-col w-full px-5`}
        >
          {/* <DashBoardHeader /> */}
          {/* <div className="flex flex-row items-center mt-9">
            <div
              className={`flex flex-row items-center py-2 pl-2 pr-6`}
              onClick={() => handleBack()}
            >
              <img src={back} className="" alt="rate-star" />
            </div>
            {/* <span className="text-base">Go back home</span>
          </div> */}
          <div className="w-full p-0">
            <BackButton onPress={handleBack} />
          </div>
          
          <div className="bg-white flex flex-col ">
          {
            tipperTitle ? (
              <p className="mt-5 text-3xl font-semibold text-primary-pwa">
                {tipperTitle}
              </p>
            ):(
              <>
              {nonShukranId ? (
              <p className="mt-5 text-3xl font-semibold text-primary-pwa">
                How much would you like to tip {nonShukranId}?
              </p>
            ) : queryId ? (
              <p className="mt-5 text-3xl font-semibold text-primary-pwa">
                How much would you like to{" "}
                {queryId === "LP1261" ? "Pay" : "tip"}
                {` ${queryName}`}?
              </p>
            ) : queryFromPath &&
              locationPath?.pathname?.split("/").length === 2 &&
              locationPath?.pathname?.split("/")[1] !== "tip" ? (
              <p className="mt-5 text-3xl font-semibold text-primary-pwa">
                How much would you like to{" "}
                {locationPath?.pathname?.split("/")[1] === "KE4237"
                  ? "pay "
                  : "tip "}
                {nameFromPath}?
              </p>
            ) : (
              <p className="mt-5 text-3xl font-semibold text-primary-pwa">
                How much would you like to tip{" "}
                {receiverName ? receiverName : "Joe "}{" "}
                {receiverLastName ? receiverLastName : "Doe"}?
              </p>
            )}
              </>
            )
          }
            

            {locationPath?.pathname?.split("/")[1] === "KE4237" ? (
              <></>
            ) : (
              <form 
              className="flex flex-col justify-between h-4/5 mt-5 pb-4"
              onSubmit={handleSubmit(handleNext)}
            >
              {/* Currency selector remains the same */}
              <div
                style={{ width: "35%", border: "1px solid #6CCBD1" }}
                className="text-[#6CCBD1] border-[#6CCBD1] border-1 h-[60px] rounded-xl flex items-center mb-5 justify-evenly p-4"
              >
                <div>
                  <img
                    src={selectedCurrency === "KSH" ? kenya : usa}
                    alt="flag"
                  />
                </div>
                <select
                  style={{ outline: "none" }}
                  className="text-[#6CCBD1] border-[#6CCBD1] bg-transparent w-60 "
                  onChange={(e) => {
                    setSelectedCurrency(e.target.value);
                  }}
                  value={selectedCurrency}
                >
                  <option value="KSH">KSH</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            
              {/* New shadcn/ui input with button */}
              <div className="relative w-full">
                <Input
                  type="number"
                  className={`h-14 pr-12 rounded-full pl-4 italic ${
                    isFocused ? "border-primary-pwa" : "border-black/30"
                  }`}
                  placeholder={queryId === "EM4490" ? "Enter amount " : "Enter amount"}
                  {...register("customAmount", {
                    required: true,
                  })}
                  onChange={(e) => {
                    setCustomAmount(e.target.value);
                  }}
                />
                <ShadcnButton 
                  type="submit"
                  size="icon"
                  variant="ghost"
                  className="h-9 w-9 absolute right-2 top-1/2 -translate-y-1/2 bg-[#6CCBD1] hover:bg-[#5bb8bd] rounded-full"
                >
                  <ArrowRight className="h-5 w-5" color="white"/>
                </ShadcnButton>
              </div>
            
              {errors?.customAmount && (
                <p className="text-red-600 text-sm text-center">
                  Ensure you have entered an amount
                </p>
              )}
            </form>
            )}

            {queryId !== "LP1261" ||
              (locationPath?.pathname?.split("/")[1] !== "KE4237" && (
                <div className="bg-[#6CCBD1]/20 mt-4 rounded-md p-1 drop-shadow-md flex flex-row">
                  <div className="flex flex-col justify-center">
                    <img
                      src={ExclamationIcon}
                      alt="Not found icon"
                      className="text-primary-pwa h-5 w-5"
                    />
                  </div>
                  <div className="flex flex-col ml-3">
                    <span className="text-xs text-primary-pwa">
                      {
                        "Tip Kshs. 100 and above to level up your tipping badge!"
                      }
                    </span>
                  </div>
                </div>
              ))}
            {amounts.length !== 0 ? (
              <ul
                className={`${
                  queryId === "LP1261" ? "mt-10" : ""
                } bg-white py-4 flex flex-col items-center w-full rounded-3xl m-0`}
              >
                {queryId === "LP1261" ? (
                  <li>Pick a drink below to buy</li>
                ) : (
                  <>
                    {locationPath?.pathname?.split("/")[1] === "KE4237" ? (
                      <></>
                    ) : (
                      <></>
                      // <li>Or pick an amount below</li>
                    )}
                  </>
                )}

                {/* {queryId === "CS1331" && (
                  <>
                    {chezsonia.map((amount, idx) => (
                      <li
                        className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                        key={idx}
                        onClick={() => handleSetAmount(amount)}
                      >
                        <button
                          type="button"
                          className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                        >
                          {selectedCurrency}{" "}
                          {selectedCurrency === "USD" ? amount / 100 : amount}
                          <img
                            src={
                              amount === 2000
                                ? EmojiSix
                                : amount === 1000
                                ? EmojiFive
                                : amount === 500
                                ? EmojiFour
                                : ""
                            }
                            className="h-6 w-6"
                            alt="emoji"
                          />
                        </button>
                      </li>
                    ))}
                  </>
                )} */}
                {locationPath?.pathname?.split("/")[1] === "KE4237" && (
                  <>
                    {/* {kinetic.map((amount, idx) => (
                      <li
                        className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                        key={idx}
                        onClick={() => handleSetAmount(amount)}
                      >
                        <button
                          type="button"
                          className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                        >
                          {selectedCurrency}{" "}
                          {selectedCurrency === "USD" ? amount / 100 : amount}
                          <img
                            src={
                              amount === 20000
                                ? EmojiSix
                                : amount === 10000
                                ? EmojiFive
                                : amount === 5000
                                ? EmojiFour
                                : ""
                            }
                            className="h-6 w-6"
                            alt="emoji"
                          />
                        </button>
                      </li>
                    ))} */}
                  </>
                )}
                {queryId === "LP1261" && (
                  <>
                    {launchpad.map((amount, idx) => (
                      <li
                        className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                        key={idx}
                        onClick={() => handleSetAmount(amount)}
                      >
                        <button
                          type="button"
                          className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                        >
                          {amount === 350 && (
                            <span className="text-subtle text-lg">
                              Beer/Cider ~{" "}
                            </span>
                          )}
                          {amount === 600 && (
                            <span className="text-subtle text-lg">
                              Cocktail ~{" "}
                            </span>
                          )}
                          {amount}
                          {/* <img
                          src={
                            amount === 600
                              ? EmojiSix
                              : amount === 350
                              ? EmojiFour
                              : ""
                          }
                          className="h-6 w-6"
                          alt="emoji"
                        /> */}
                        </button>
                      </li>
                    ))}
                  </>
                )}
                {/* {queryId === "EM4490" && (
                  <>
                    {emaiyan.map((amount, idx) => (
                      <li
                        className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                        key={idx}
                        onClick={() => handleSetAmount(amount)}
                      >
                        <button
                          type="button"
                          className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                        >
                          {selectedCurrency}{" "}
                          {selectedCurrency === "USD" ? amount / 100 : amount}
                          <img
                            src={
                              amount === 10000
                                ? EmojiSix
                                : amount === 5000
                                ? EmojiFour
                                : amount === 2000
                                ? EmojiTwo
                                : ""
                            }
                            className="h-6 w-6"
                            alt="emoji"
                          />
                        </button>
                      </li>
                    ))}
                  </>
                )} */}
                {/* {(tipData?.shukranId === "CL2428" || queryId === "CL2428") && (
                  <>
                    {cabanas.map((amount, idx) => (
                      <li
                        className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                        key={idx}
                        onClick={() => handleSetAmount(amount)}
                      >
                        <button
                          type="button"
                          className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                        >
                          {selectedCurrency}{" "}
                          {selectedCurrency === "USD" ? amount / 100 : amount}
                          <img
                            src={
                              amount === 2000
                                ? EmojiSix
                                : amount === 1500
                                ? EmojiFour
                                : amount === 1000
                                ? EmojiTwo
                                : ""
                            }
                            className="h-6 w-6"
                            alt="emoji"
                          />
                        </button>
                      </li>
                    ))}
                  </>
                )} */}
                {/* {queryId !== "CS1331" &&
                  queryId !== "CL2428" &&
                  queryId !== "EM4490" &&
                  tipData?.shukranId !== "CL2428" &&
                  locationPath?.pathname?.split("/")[1] !== "KE4237" &&
                  queryId !== "LP1261" && (
                    <>
                      {amounts.map((amount, idx) => (
                        <li
                          className="text-black my-2 font-bold text-lg w-full bg-accent-light rounded-3xl"
                          key={idx}
                          onClick={() => handleSetAmount(amount)}
                        >
                          <button
                            type="button"
                            className="rounded-3xl mx-auto p-2 text-subtle text-xl flex flex-row"
                          >
                            {selectedCurrency}{" "}
                            {selectedCurrency === "USD" ? amount / 100 : amount}
                            <img
                              src={
                                amount === 1000
                                  ? EmojiSix
                                  : amount === 500
                                  ? EmojiFour
                                  : amount === 200
                                  ? EmojiTwo
                                  : ""
                              }
                              className="h-6 w-6"
                              alt="emoji"
                            />
                          </button>
                        </li>
                      ))}
                    </>
                  )} */}
              </ul>
            ) : (
              ""
            )}
            {
              contributionStatus===false?
            <div className="flex flex-col ">
              <p className="text-center text-[#999999]">
                Wish to tip an individual directly?
              </p>
              <li
                onClick={() => navigate("/search")}
                className=" text-black p-4 m-3 flex items-center justify-center font-bold text-lg w-full border border-solid border-accent bg-transparent rounded-full"
              >
                <button
                  type="button"
                  className="rounded-3xl text-accent mx-auto text-subtle text-xl flex flex-row"
                >
                  TIP AN INDIVIDUAL
                </button>
              </li>
              {
                tipperTitle==null&& <div class="bg-purple-50 p-6 rounded-lg shadow-md max-w-md self-center">
                <div className="flex justify-between align-middle">
                  <h2 class="text-2xl font-semibold mb-4">How does it work?</h2>
                </div>
                <p class="text-gray-700">
                  Enter the amount and the currency you would like to tip the
                  team at {queryName ?? nonShukranId ?? nameFromPath} as a
                  group. In addition, you can tip an individual as well by
                  clicking tip an individual.
                </p>
              </div>
              }
             
            </div>:            <ShadcnButton className="w-full rounded-full text-white text-md font-semibold h-[80px] bg-primary-pwa" onClick={() => handleNext({customAmount})}>
  Send Contribution
  <ArrowRight className="ml-2 h-4 w-4" color="white" />
</ShadcnButton>


            }

            

            {customAmountError && (
              <div className="mx-auto mt-5 py-3 px-2 text-center rounded-md bg-red-200">
                <span className="text-black text-large font-bold">
                  {customAmountError}
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className={`${isInsufficient ? "absolute blur-none" : "hidden"}
        flex justify-center items-center w-96
        `}
        >
          <Popup
            setIsInsufficient={setIsInsufficient}
            setIsClicked={setIsClicked}
            isClicked={isClicked}
          />
        </div>
      </div>
      <div
        className={`${showSessionExpired ? "absolute blur-none" : "hidden"}
        flex flex-col justify-center items-center w-full h-full
        `}
      >
        <SessionExpired />
      </div>
      <ToastContainer />
    </div>
  );
}
