
import {
    useQuery,
    useQueryClient,
    UseMutationResult,
    UseQueryResult,
    useMutation,
  } from "@tanstack/react-query";
  import { AxiosInstance, AxiosResponse } from "axios";

  import { constructApiUrl } from "../../../../utils/constructApiUrl";
  import useAxios from "../../../../shared/useAxios";
  import { useMemberQueryTypes } from "./userMemberQueryTypes";
  import axios from "axios";
  import { BASE_URL } from "../../../../constants/network";


// Function to search for an account by Shukran ID
export const searchAccount = async (searchParams: string): Promise<any> => {
    // Check if searchParams is a full URL path (for pagination) or just a shukran_id
    const isFullPath = searchParams.includes('/accounts/auth/search/');
    
    let apiUrl;
    if (isFullPath) {
      // Remove any leading slash to prevent double slashes
      const cleanPath = searchParams.startsWith('/') ? searchParams.slice(1) : searchParams;
      apiUrl = `${BASE_URL}${cleanPath}`;
    } else {
      apiUrl = `${BASE_URL}accounts/auth/search/?shukran_id=${searchParams}`;
    }
    
    try {
      const response = await axios.get(apiUrl);
      return response;
    } catch (error: any) {
      console.log("SEARCH ERROR ", error?.response?.statusText);
      throw new Error(error?.response?.statusText || "An unexpected error occurred");
    }
  };
  
  // Custom hook for searching accounts
  export const useSearchAccount = () => {
    return useMutation({
      mutationKey: [useMemberQueryTypes.SEARCH_MEMBERS],
      mutationFn: (searchParams: string) => searchAccount(searchParams)
    });
  };