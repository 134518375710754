import React, { useState } from "react";
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";
import { ChevronRight, X } from "lucide-react";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import SelectCurrency from "./SelectCurrency";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components-shadcn/ui/dialog";
import { toast } from "sonner";
import MultipleCurrencySelector from "./MultipleCurrencySelector";

const TipEntity = () => {
  
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { 
    activeEntity, 
    setEntityToTip, 
    setEntityTipAmount,
    entityJARToTip, 
    setEntityJARToTip,
    setEntityJARTipAmount,
    entityToTip, 
    totalAmountToTip,
    selectedCurrency , 
    setTotalAmountToTip,
  } = useTipJarLocalState();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(totalAmountToTip||"");

  console.log("entityToTip", entityToTip);

  const handleBack = () => {
    setEntityToTip(null);
    setEntityJARToTip(null);
    setTotalAmountToTip(0);
    navigate(-1);
  };

  const handleProceedClick = () => {
    if (!amount || amount <= 0) {
      toast.error("Please enter a valid amount");
      return;
    }

    if(!entityToTip&&!entityJARToTip) {
      toast.error("Please select an entity or entityJAR to tip");
      return;
    }

    
    if (entityToTip) {
      const entityTip = {};
      entityTip[entityToTip.id] = parseFloat(amount) || 0;
      setEntityTipAmount(entityTip);
    }
    if(entityJARToTip) {
      const entityJARTip = {};
      entityJARTip[entityJARToTip.id] = parseFloat(amount) || 0;
      setEntityJARTipAmount(entityJARTip);
    }
   
    setShowConfirmDialog(true);
  };

  const handleSendTipClicked=()=>{
    setTotalAmountToTip(amount);
    navigate("/tipjar/select-payment-method")

  }

  return (
    <div className="flex flex-col w-full h-screen px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      <h1 className="text-2xl font-medium text-[#6CCBD1] mb-6">
        {
          entityToTip?` How much would you like to tip ${entityToTip?.name}?`:` How much would you like to tip ${entityJARToTip?.name}?`
        }
       
      </h1>

      <MultipleCurrencySelector/>

      <div className="relative">
        <Input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter Amount"
          className="pl-3 h-[55px] shadow-md border-2 border-[#77CFD4] rounded-full w-full flex items-center pr-16"
        />
        <button
          className="absolute right-2 top-2 w-10 h-10 bg-[#6CCBD1] rounded-full flex items-center justify-center"
          onClick={handleProceedClick}
        >
          <ChevronRight color="white" size={24} />
        </button>
      </div>

      <div className="mt-auto mb-6">
        <Button 
          className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white h-[60px] rounded-full"
          onClick={handleProceedClick}
        >
          PROCEED
        </Button>
      </div>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader className="flex flex-row items-center justify-between">
            <DialogTitle className="text-xl font-semibold">Confirm tips</DialogTitle>
            {/* <button 
              onClick={() => setShowConfirmDialog(false)}
              className="w-8 h-8 flex items-center justify-center text-gray-500 hover:text-gray-700"
            >
              <X size={20} />
            </button> */}
          </DialogHeader>

          <div className="space-y-6 py-4">
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-500 mb-1">Send Tip:</p>
                <p className="font-medium">
                TipJAR -  {
          entityToTip?`${entityToTip?.name} : ${selectedCurrency} ${parseFloat(amount).toLocaleString()}`:` ${entityJARToTip?.name} : ${selectedCurrency} ${parseFloat(amount).toLocaleString()}`
        }
                
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-500 mb-1">Total Tip:</p>
                <p className="font-medium">{selectedCurrency} {parseFloat(amount).toLocaleString()}</p>
              </div>
            </div>

            <Button 
              className="w-full bg-[#64C8CE] hover:bg-[#50a2a7] text-white h-[50px] rounded-full"
              onClick={() => handleSendTipClicked()}
            >
              SEND TIP
            </Button>

            <div className="text-center space-y-3">
              <p className="text-sm text-gray-600">{`Would you like to include individuals from ${activeEntity?.name} and tip them separately?`}</p>
              <Button
                variant="outline"
                className="w-full border-[#F2C773] text-[#F2C773] hover:bg-[#F2C773] hover:text-white h-[50px] rounded-full"
                onClick={() => navigate("/add-individuals-to-entity-tip/")}
              >
                ADD INDIVIDUALS
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TipEntity;