
import TippingSettings from "./TipjarSettings";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
const TipJarSettingsPage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
      
        navigate(-1);
    };

    return (
        <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
            <div className="w-full pl-4">
            <BackButton onPress={handleBack} />
            </div>
                                
            
 <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-1 mt-16">
    <TippingSettings />

 </div>

        </div>
    )
};

export default TipJarSettingsPage