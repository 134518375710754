import React from 'react';
import { ChevronLeft, Plus } from 'lucide-react';
import { Button } from '../../../components-shadcn/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components-shadcn/ui/select';
import { useTipJarLocalState } from '../../Tipjar/state/local-state/useTipjarLocalState';
import { X } from 'lucide-react';
import { Loader2 } from "lucide-react";



const ConfirmDetails = ({ 
  onBack, 
  onAddNew, 
  onConfirm, 
  shukranMembers, 
  phoneMembers,
  onUpdateMemberDepartment,
  isAddingMultipleMembersToEntity,
  onRemoveMember  // Add this prop
}) => {
  const { entityDepartments } = useTipJarLocalState();
  const activeDepartments = entityDepartments?.filter(dept => dept.active) || [];

  const renderMember = (member, isShukran, index) => {
    const name = isShukran 
      ? `${member.user.first_name} ${member.user.last_name}`
      : `${member.first_name} ${member.last_name}`;
    const id = isShukran ? member.user.shukran_id : member.phone;
    const initials = `${name.charAt(0)}${name.split(' ')[1]?.charAt(0) || ''}`;

    return (
      <div key={id} className="flex gap-4 mb-4 w-full">
        {/* Member Info Card */}
        <div className="flex-1 bg-white rounded-md p-4 border-[1px] border-slate-300">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="w-8 h-8 rounded-full bg-gradient-to-br from-pink-400 to-cyan-400 flex items-center justify-center text-white font-medium">
                {initials}
              </div>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-900">{name}</span>
                <span className="text-xs text-gray-400">{id}</span>
              </div>
            </div>
            <button 
              onClick={() => onRemoveMember(isShukran ? member.user.id : member.phone, isShukran)}
              className="text-red-500 hover:text-red-700"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        </div>

        {/* Department Select Card */}
        <div className="w-1/3 bg-white rounded-md p-4 border-[1px] border-slate-300">
          <Select
            value={member.department_id}
            onValueChange={(value) => onUpdateMemberDepartment(
              isShukran ? member.user.id : member.phone,
              isShukran,
              value
            )}
          >
            <SelectTrigger className="w-full border-none h-full">
              <SelectValue placeholder="Select role" />
            </SelectTrigger>
            <SelectContent>
              {activeDepartments.map((department) => (
                <SelectItem key={department.id} value={department.id}>
                  {department.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-white">
      <div className=" py-4 ">
        <div className="flex items-center gap-4">
          <button 
            onClick={onBack}
            className="text-gray-600 hover:text-gray-800"
          >
            <ChevronLeft className="h-6 w-6" />
          </button>
          <h1 className="text-xl font-semibold text-gray-900">Confirm members</h1>
        </div>
      </div>

      <div className="flex-1 ">
        {shukranMembers.map((member, index) => renderMember(member, true, index))}
        {phoneMembers.map((member, index) => renderMember(member, false, index + shukranMembers.length))}
      </div>

      <div className="p-6 space-y-3 bg-white border-t">
        <Button
          onClick={onAddNew}
          disabled={isAddingMultipleMembersToEntity}
          variant="outline"
          className="w-full border-2 border-[#F2C773] text-[#F2C773] h-14 rounded-full hover:bg-[#F2C773]/10 font-medium"
        >
          ADD NEW <Plus className="ml-2 h-5 w-5" />
        </Button>

        <Button
          onClick={onConfirm}
          disabled={isAddingMultipleMembersToEntity}
          className="w-full bg-[#F2C773] text-white h-14 rounded-full hover:bg-[#F2C773]/90 font-medium"
        >
         {isAddingMultipleMembersToEntity ? (
                     <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                   ) : (
                     "CONFIRM"
                   )}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmDetails;