import React, { useEffect, useState } from "react";
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";
import { Card } from "../../components-shadcn/ui/card";
import { Search, X, Hotel, Users, Users2,Loader2, ChevronRight } from "lucide-react";
import BackButton from "../globals/BackButton";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "../../components-shadcn/ui/checkbox";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import EntityJarIcon from "../../assets/Images/entity-jar-icon.png";
import IndividualJarIcon from "../../assets/Images/individual-jar-icon.png";
import GroupJarIcon from "../../assets/Images/group-tipjar-icon.png";
import { OctagonAlert } from "lucide-react";

const HeaderComponent = ({
  icon,
  title,
  image,
  subtitle,
  strokeColor,
  fillColor,
  isChecked,
  onCheckChange,
}) => {

  if(title==="Individuals"){
    return (
      <></>
    )
  }
  
  return (
    <Card
      className="flex items-center justify-between p-3 mb-4"
      style={{ backgroundColor: fillColor, borderColor: strokeColor }}
    >
      <div className="flex items-center">
        <div
          className="w-10 h-10 rounded-full flex items-center justify-center mr-3"
          style={{ backgroundColor: strokeColor }}
        >
          <img src={image} alt="icon" />
        </div>
        <div>
          <h5 className="font-medium">{title}</h5>
          <p className="text-lg text-gray-500">{subtitle}</p>
        </div>
      </div>
      <Checkbox
        checked={isChecked}
        onCheckedChange={onCheckChange}
        className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
      />
    </Card>
  );
};

const TipGroupAndSearchMembers = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);
  const [isHeaderChecked, setIsHeaderChecked] = useState(true);
  
  const {
    selectedType,
    activeEntityJAR,
    activeEntity,
    setEntityToTip,
    setEntityJARToTip,
    defaultEntityJAR,
    setSelectedIndividualsToTip,
    entityJARMembers,
  } = useTipJarLocalState();

  const [members, setMembers] = useState(entityJARMembers);

  useEffect(() => {
    if(defaultEntityJAR?.jar_members_can_receive_individual_tip===false){
          const filteredMembers= entityJARMembers?.filter((member) => member.can_join_entity_jar === false);
          setMembers(filteredMembers);
      }


  }, [defaultEntityJAR, entityJARMembers]);

  const getTotalSelected = () => {
    let total = selectedIndividuals.length;
    console.log(total);
    if (isHeaderChecked) {
      // Add 1 to total if header is checked (for entity or entityJAR)
      if (selectedType === "entity" || selectedType === "entityJAR") {
        total += 1;
      }
    }
    return total;
  };

  const navigate = useNavigate();

  const handleBack = () => {
    setSelectedIndividualsToTip([]);
    setEntityToTip(null);
    setEntityJARToTip(null);
    navigate(-1);
  };

  const handleProceedClicked = () => {
    setSelectedIndividualsToTip(selectedIndividuals);
    if (selectedType === "entity") {
      setEntityToTip(isHeaderChecked ? activeEntity : null);
    } else if (selectedType === "entityJAR") {
      setEntityJARToTip(isHeaderChecked ? activeEntityJAR : null);
    }

    navigate("/tip-amount-calculator");
  };

  const filteredIndividuals = members?.filter((individual) =>
    `${individual.user.first_name} ${individual.user.last_name}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const toggleIndividual = (individual) => {
    setSelectedIndividuals((prev) =>
      prev.some((i) => i.id === individual.id)
        ? prev.filter((i) => i.id !== individual.id)
        : [...prev, individual]
    );
  };

  const removeSelectedIndividual = (individual) => {
    setSelectedIndividuals((prev) =>
      prev.filter((i) => i.id !== individual.id)
    );
  };

  const getHeaderProps = () => {
    switch (selectedType) {
      case "individual":
        return {
          icon: <Users size={24} />,
          image: IndividualJarIcon,
          title: "Individuals",
          subtitle: activeEntity?.name,
          strokeColor: "#ABDADD",
          fillColor: "#F5FEFF",
        };
      case "entity":
        return {
          icon: <Hotel size={24} />,
          title: "Hotel TipJAR",
          image: EntityJarIcon,
          subtitle: activeEntity?.name,
          strokeColor: "#F5E1BA",
          fillColor: "#FFFCF5",
        };
      case "entityJAR":
        return {
          icon: <Users2 size={24} />,
          title: "Group TipJAR",
          image: GroupJarIcon,
          subtitle: activeEntityJAR?.name,
          strokeColor: "#C5B7DF",
          fillColor: "#F8F5FF",
        };
      default:
        return {};
    }
  };

  const disableProceed = () => {
    if (selectedType === "entity" || selectedType === "entityJAR") {
      return false;
    }
    if (!selectedIndividuals || selectedIndividuals.length === 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2 overflow-y-hidden">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

     

      <HeaderComponent
        {...getHeaderProps()}
        isChecked={isHeaderChecked}
        onCheckChange={(checked) => setIsHeaderChecked(checked)}
      />
      
      {
        selectedType==="entity"? <h1 className="text-2xl font-semibold text-[#50A2A7] mb-4 mt-4">
        { `You're tipping ${activeEntity.name}. You can also tip individuals seperately or proceed`}
        </h1>:<>
        {
          selectedType==="entityJAR"?<h1 className="text-2xl font-semibold text-[#50A2A7] mb-4 mt-4">
          { `You're tipping ${activeEntityJAR.name}. You can also tip individuals seperately or proceed`}
          </h1>:<h1 className="text-2xl font-semibold text-[#50A2A7] mb-4 mt-4">
        { `Select individuals you would like to tip`}
        </h1>
        }
        </> 
      }
      <p className="mb-2">
        Add Individuals: {selectedIndividuals.length} selected
      </p>

      {selectedIndividuals.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedIndividuals.map((individual) => (
            <Button
              key={individual.id}
              variant="secondary"
              className="bg-[#CBE3E5] text-black rounded-full text-sm py-1 px-3"
              onClick={() => removeSelectedIndividual(individual)}
            >
              {`${individual.user.first_name} ${individual.user.last_name}`}{" "}
              <X size={14} className="ml-1" />
            </Button>
          ))}
        </div>
      )}

      <div className="relative mb-4">
        <Search
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={20}
        />
        <Input
          className="pl-10 pr-4 w-full border-2 border-[#6CCBD1] rounded-full h-[60px]"
          placeholder="Search name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="flex-grow overflow-auto">
        {!members ? (
          <div className="w-full flex flex-col gap-2">
            <Skeleton className="w-full h-[100px] rounded" />
            <Skeleton className="w-full h-[100px] rounded" />
            <Skeleton className="w-full h-[100px] rounded" />
          </div>
        ) : (
          <>
            {filteredIndividuals?.length === 0 && (
              <div className="w-full flex flex-col items-center gap-2 mt-10">
                <OctagonAlert size={24} className="text-gray-400" />
                <p className="text-sm">No Members yet</p>
              </div>
            )}
            {filteredIndividuals?.map((individual) => {
              const isSelected = selectedIndividuals.some(
                (i) => i.id === individual.id
              );
              return (
                <div
                  key={individual.id}
                  className="flex items-center p-3 mb-2 rounded-lg border border-[#F5F5F5] cursor-pointer"
                >
                  <div
                    className="flex-grow flex items-center"
                    onClick={() => toggleIndividual(individual)}
                  >
                    <div className="w-10 h-10 rounded-full bg-[#6CCBD1] text-white flex items-center justify-center mr-3">
                      {individual.user.first_name[0]}
                    </div>
                    <div>
                      <p className="font-semibold">{`${individual.user.first_name} ${individual.user.last_name}`}</p>
                      <p className="text-sm text-gray-500">
                        {individual.user.shukran_id}
                      </p>
                    </div>
                  </div>
                  <Checkbox
                    checked={isSelected}
                    onCheckedChange={() => toggleIndividual(individual)}
                    className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                  />
                </div>
              );
            })}
          </>
        )}
      </div>

      <div className="w-full p-2">
        <Button
          className="w-full bg-[#F2C773] hover:bg-[#e0b66a] text-white h-[60px] rounded-full"
          onClick={handleProceedClicked}
          disabled={disableProceed()}
        >
          PROCEED
        </Button>
      </div>


    </div>
  );
};

export default TipGroupAndSearchMembers;
