import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddButton from '../../globals/AddButton';
import { CirclePlus, ChevronRight } from 'lucide-react';
import NewDepartment from './NewDepartment';
import { useTipJarLocalState } from '../../Tipjar/state/local-state/useTipjarLocalState';
import { useFetchDepartments } from '../../Groups/state/server-state/useDepartmentServerState';
import NoDepartment from "../../../assets/Images/no_department.png"
import WelcomeDialog from './WelcomeDialog';
import { useRegistrationLocalState } from '../state/local-state/useRegistrationLocalState';


const DepartmentSetup = ({ onViewChange }) => {
  const navigate = useNavigate();
  const [showNewDepartment, setShowNewDepartment] = useState(false);
  const {activeEntity, setDepartmentList,entityDepartments} = useTipJarLocalState();
  const {hasShownDepartmentSetup, setHasShownDepartmentSetup} = useRegistrationLocalState();

  // Notify parent component when view changes
  useEffect(() => {
    onViewChange(showNewDepartment ? 'new' : 'main');
  }, [showNewDepartment, onViewChange]);

  const { data: depts, refetch } = useFetchDepartments(activeEntity?.id);

  useEffect(() => {
    if (depts && depts.length > 0) {
      setDepartmentList(depts);
    }
  }, [depts, setDepartmentList]);

  console.log("DEPT LIST ---->", depts);

  if (showNewDepartment) {
    return <NewDepartment onBack={() => setShowNewDepartment(false)} refetch={refetch} />;
  }

  return (
    <div className="flex flex-col min-h-screen bg-white relative">
      <div className="px-2 flex-1">
        <AddButton 
          title="New Department"   
          onClick={() => setShowNewDepartment(true)}  
        />
        <WelcomeDialog open={hasShownDepartmentSetup} onOpenChange={() => setHasShownDepartmentSetup(false)} title="Welcome to Department Setup!" description="This is where you'll organize your team into departments. Each department can have its own tip distribution points and members." message={`Click the "New Department" button to create your first department and start organizing your team structure.`} />
        <div className="flex items-center mb-8 mt-6">
          <h2 className="text-gray-700">Departments (0)</h2>
        </div>
        {
          entityDepartments?.length > 0 ? <div>
          {
            entityDepartments?.map((department) => (
              <div className=" empty" key={department?.id}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  className="border-2 border-[#F5F5F5] mb-3 min-h-[65px] p-2"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    className="w-fit px-2"
                  >
                    <div className="w-[30px] h-[30px] rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-1"></div>
                    <div className="ml-2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{ color: "#404B5A", fontSize: "14px" }}
                          className="font-semibold"
                        >
                          {department?.name}
                        </p>
                        <div
                          className="ml-1"
                          style={{
                            border: "1px solid #6CCBD1",
                            width: "25px",
                            height: "25px",
                            borderRadius: "12.5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#F0FEFF",
                            color: "#6CCBD1",
                            fontSize: "8px",
                          }}
                        >
                          {department?.distribution_points}
                        </div>
                      </div>
                      <p style={{ color: "#929FB1", fontSize: "10px" }}>
                        {department?.members?.length || 0} Members
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    className="w-fit px-2"
                  >
                  </div>
                </div>
              </div>
            </div>
              
            ))
          }
          </div>:
        <div className="mt-6 empty gap-2 flex flex-col">
              <img
                src={NoDepartment}
                alt="No department"
                className="h-[80px] w-[140px]"
              />
              <p className="w-40 mt-6 text-center text-slate-300">
                  Created departments will appear here
              </p>
        </div>
        }
      </div>
    </div>
  );
};

export default DepartmentSetup;