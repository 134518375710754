import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../../src/utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { DepartmentQueryTypes } from "./departmentQueryTypes";

const fetchDepartments = async (
  $http: AxiosInstance,
  entityId: string
): Promise<any> => {
  const apiUrl = constructApiUrl(
    `/tipjar/entity/department/${entityId}/list-by-entity`
  );

  try {
    const response = await $http.get(apiUrl);
    return response.data;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    throw new Error("An unexpected error ocuurred");
  }
};

export const useFetchDepartments = (entityId: string) => {
  const $http = useAxios();
  return useQuery<AxiosResponse<any, any>, unknown>({
    queryKey: [DepartmentQueryTypes.FETCH_DEPARTMENT, entityId],
    queryFn: () => fetchDepartments($http, entityId)
  });
};

const createDepartment = async (
  $http: AxiosInstance,
  options: any
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/department/`);
  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    throw new Error("An unexpected error occurred");
  }
};

export const useCreateDepartment = () => {
  const queryClient = useQueryClient();
  const $http = useAxios();
  return useMutation({
    mutationKey: [DepartmentQueryTypes.CREATE_DEPARTMENT],
    mutationFn: (options: any) => createDepartment($http, options),
  });
};

type UpdateDepartmentPointsPayload = {
  name: string;
  distribution_points: number;
  department_id: string;
};

const updateDepartment = async (
  $http: AxiosInstance,
  options: UpdateDepartmentPointsPayload
): Promise<any> => {
  const { department_id, name, distribution_points } = options;
  const apiUrl = constructApiUrl(
    `/tipjar/entity/department/${department_id}/update-department/`
  );
  try {
    const payload = { name, distribution_points };
    const response = await $http.post(apiUrl, payload);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    throw new Error("An unexpected error occurred");
  }
};

export const useUpdateDepartment = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [DepartmentQueryTypes.UPDATE_DEPARTEMNT],
    mutationFn: (options: UpdateDepartmentPointsPayload) =>
      updateDepartment($http, options),
  });
};

const activateDepartment = async (
  $http: AxiosInstance,
  options: {
    department_id: string;
  }
): Promise<any> => {
  const apiUrl = constructApiUrl(
    `/tipjar/entity/department/activate-department/`
  );
  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error:any) {
    const errorMsg=error?.response?.data?.detail||"An unexpected error occurred";
    throw new Error(errorMsg);
  }
};

export const useActivateDepartment = () => {
  const $http = useAxios();

  return useMutation({
    mutationKey: [DepartmentQueryTypes.ACTIVATE_DEPARTMENT],
    mutationFn: (options: { department_id: string }) =>
      activateDepartment($http, options),
  });
};

const deactivateDepartment = async (
  $http: AxiosInstance,
  options: {
    department_id: string;
  }
): Promise<any> => {
  const apiUrl = constructApiUrl(
    `/tipjar/entity/department/deactivate-department/`
  );
  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useDeactivateDepartment = () => {
  const $http = useAxios();

  return useMutation({
    mutationKey: [DepartmentQueryTypes.DEACTIVATE_DEPARTMENT],
    mutationFn: (options: { department_id: string }) =>
      deactivateDepartment($http, options),
  });
};
