import React, { useState } from 'react';
import { ChevronLeft, HelpCircle, Loader2 } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import { Input } from '../../../components-shadcn/ui/input';
import { Button } from "../../../components-shadcn/ui/button"
import { useTipJarLocalState } from '../../Tipjar/state/local-state/useTipjarLocalState';
import { useCreateDepartment } from '../../Groups/state/server-state/useDepartmentServerState';
import {toast} from "sonner"
import { DepartmentQueryTypes } from '../../Groups/state/server-state/departmentQueryTypes';

const NewDepartment = ({ onBack, refetch }) => {
  const queryClient = useQueryClient();
  const [departmentName, setDepartmentName] = useState('');
  const [points, setPoints] = useState(5);
  const [showExplanation, setShowExplanation] = useState(false);
  const createDepartment = useCreateDepartment({
    onSuccess: () => {
      // Invalidate the departments query
      queryClient.invalidateQueries({
        queryKey: [DepartmentQueryTypes.FETCH_DEPARTMENT, activeEntity?.id]
      });
    }
  });
  const isCreatingDepartment = createDepartment.isLoading;
  const { activeEntity, setActiveDepartment } = useTipJarLocalState();

  const handleIncrement = () => {
    if (points < 10) {
      setPoints(points + 1);
    }
  };

  const handleDecrement = () => {
    if (points > 0) {
      setPoints(points - 1);
    }
  };

  const handleCreateDepartment = async () => {
    if (!departmentName || departmentName.length <= 0) {
      toast.error("Enter department name");
      return;
    }

    if (!activeEntity) {
      toast.error("Some data is missing");
      return;
    }

    const payload = {
      name: departmentName,
      distribution_points: points,
      entity_id: activeEntity?.id,
    };

    try {
      const response = await createDepartment.mutateAsync(payload);
      if (response === 403) {
        toast.success("Department created successfully");
      }
      const newDepartment = response.data;
      setActiveDepartment(newDepartment);
      refetch()
      onBack()
    
    } catch (error) {
      toast.error("Failed to create department");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white px-4 pt-4">
      {/* Back Button */}
      <div className='w-full flex flex-row items-center gap-6 mb-6'>
        <button 
          onClick={onBack}
          className="flex items-center text-gray-600 w-fit"
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <p className='text-lg font-semibold'>New Department</p>
      </div>

      {/* Department Name Input */}
      <div className="mb-6">
        <p className="text-sm text-gray-500 mb-2">Department name</p>
        <Input
          value={departmentName}
          onChange={(e) => setDepartmentName(e.target.value)}
          placeholder="Enter department name"
          className="h-12 rounded-lg"
        />
      </div>

      {/* Points Selection */}
      <div className="mb-6 w-full">
        <p className="text-sm text-gray-500 mb-2">Set distribution points out of 10</p>
        <div className='w-full flex flex-row justify-between gap-2'>
          <button
            onClick={handleDecrement}
            className="w-[15%] h-12 rounded-lg flex items-center justify-center"
            style={{ backgroundColor: '#F2C773B2' }}
          >
            <span className="text-2xl text-white">-</span>
          </button>
          
          <Input
            value={points}
            readOnly
            className="h-12 text-center w-[69%] text-lg"
          />
          
          <button
            onClick={handleIncrement}
            className="w-[15%] h-12 rounded-lg flex items-center justify-center"
            style={{ backgroundColor: '#F2C773B2' }}
          >
            <span className="text-2xl text-white">+</span>
          </button>
        </div>
      </div>

      {/* How it works section */}
      <div className="mb-8">
        <div className="flex items-center mb-2">
          <p className="text-sm text-gray-600">How does it work?</p>
          <button
            onClick={() => setShowExplanation(!showExplanation)}
            className="ml-2 p-1 rounded-full"
            style={{ backgroundColor: '#EE5582' }}
          >
            <HelpCircle className="h-4 w-4 text-white" />
          </button>
        </div>

        {showExplanation && (
          <div 
            className="p-4 rounded-lg text-sm text-gray-600"
            style={{ backgroundColor: '#FFF9EDB2' }}
          >
            <p className="mb-2">
              Points between 0 and 10 determine the proportion of tips a team receives relative to other teams.
            </p>
            <p>
              The total distributed amount always equals the total tip received.
            </p>
          </div>
        )}
      </div>

      {/* Create Button */}
      <Button
        onClick={handleCreateDepartment}
        disabled={isCreatingDepartment}
        className="w-full bg-[#F2C773] text-white h-14 rounded-full hover:bg-[#F2C773]/90 disabled:opacity-50"
      >
        {isCreatingDepartment ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Creating Department...
          </>
        ) : (
          'CREATE DEPARTMENT'
        )}
      </Button>
    </div>
  );
};

export default NewDepartment;