import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { TipJarGroupsQueryTypes } from "./tipjarQueryTypes";
import { type } from "node:os";

const createEntity = async (
  $http: AxiosInstance,
  options?: any
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/create-entity/`);
  // const response = await $http.post(apiUrl);

  const payload = {
    name: "KARISIA",
    entity_type: "RESTAURANT",
  };

  try {
    const response = await $http.post(apiUrl, payload);
    return response;
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useCreateEntity = () => {
  const queryClient = useQueryClient();
  const $http = useAxios();

  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.CREATE_ENTITY],
    mutationFn: () => createEntity($http),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([TipJarGroupsQueryTypes.CREATE_ENTITY]);
    },
    onError: (error) => {
      throw new Error("An unexpected error occurred");
    },
  });
};

const fetchSingleEntity=async($http: AxiosInstance,entityId:any):Promise<any>=>{
  const apiUrl = constructApiUrl(`/tipjar/entity/${entityId}/`);
  try {
    const response = await $http.get(apiUrl);
    console.log("SINGLE ENTITY RESPONSE --> ", response)
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    console.log("SINGLE ENTITY ERROR --> ", error)
    throw new Error("Failed to fetch entity");
  }
}

const fetchEntities = async ($http: AxiosInstance): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/`);
  try {
    const response = await $http.get(apiUrl);
    console.log("ENTITIES DATA ---", response);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
  }
};

export const useGetEntities = (): UseQueryResult<
  AxiosResponse<any, any>,
  unknown
> => {
  const $http = useAxios();
  return useQuery<AxiosResponse<any, any>, unknown>({
    queryKey: [TipJarGroupsQueryTypes.FETCH_ENTITIES],
    queryFn: () => fetchEntities($http),
    keepPreviousData: false,
  });
};

export const useGetSingleEntity = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.FETCH_ENTITY],
    mutationFn: (entityId: string) => fetchSingleEntity($http, entityId),
  });
};

export const useGetEntitiesMutation = (): UseMutationResult<
  AxiosResponse<any, any>,
  unknown,
  void,
  unknown
> => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.FETCH_ENTITIES],
    mutationFn: () => fetchEntities($http),
  });
};

export const useInvalidateEntities = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries([TipJarGroupsQueryTypes.FETCH_ENTITIES]);
  };
};

const createEntityJar = async (
  $http: AxiosInstance,
  options?: any
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/create-entity-jar/`);
  try {
    const response = await $http.post(apiUrl, options);

    return response;
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useCreateEntityJar = () => {
  const queryClient = useQueryClient();
  const $http = useAxios();

  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.CREATE_ENTITY_JAR],
    mutationFn: (options) => createEntityJar($http, options),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([TipJarGroupsQueryTypes.CREATE_ENTITY_JAR]);
    },
    onError: (error) => {
      throw new Error("An unexpected error occurred");
    },
  });
};

const fetchSingleEntityJAR = async (
  $http: AxiosInstance,
  entityId: any
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/entity-jars/`, {
    entity_jar_id: entityId,
  });
  try {
    const response = await $http.get(apiUrl);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    console.log("error", error?.response?.data?.msg);
    throw new Error( `${error?.response?.data?.msg}`);
  }
}

export const useFetchSingleJarMutation=()=>{
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.FETCH_ENTITY_JAR],
    mutationFn: (entityId: string) => fetchSingleEntityJAR($http, entityId),
  });
}

const fetchJars = async ($http: AxiosInstance, entityId: any): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/entity-jars/`, {
    entity_id: entityId || "",
  });
  try {
    const response = await $http.get(apiUrl);

    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    console.log("error", error?.response?.data?.msg);
    throw new Error( `${error?.response?.data?.msg}`);
  }
};

export const useGetJars = (entityId: string) => {
  const $http = useAxios();

  return useQuery<AxiosResponse<any, any>, unknown>({
    queryKey: [TipJarGroupsQueryTypes.FETCH_ENTITY_JARS],
    queryFn: () => fetchJars($http, entityId),
    enabled: entityId !== null,
    keepPreviousData: true,
  });
};

export const useGetJARsMutation = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.FETCH_ENTITY_JARS],
    mutationFn: (entityId: string) => fetchJars($http, entityId),
  });
};

type DistributionAmountType = {
  user_id: number;
  amount: number;
};
const disbursePointSystem = async (
  $http: AxiosInstance,
  payload: {
    entity_jar: string;
    distribution_amounts: DistributionAmountType[];
  }
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/point-system-disburse/`);
  try {
    const response = await $http.post(apiUrl, payload);

    return response;
  } catch (error:any) {
    
    const errorMsg=error?.response?.data.data
    
    if(errorMsg&&typeof errorMsg==="object"){
      const keys=Object.keys(errorMsg)
      const entries=Object.entries(errorMsg)
      if(keys&&keys.length>0){
        let msg="Check on the following "
        for(let i=0;i<keys.length;i++){
          msg+=`${keys[i]},  `
        }
        throw new Error(entries.toString()||msg);
      }
      
    }else if(errorMsg&&typeof errorMsg==="string"){
      throw new Error(errorMsg);
    }
    throw new Error("An unexpected error occurred");
  }
};

export const useDisbursePointSystem = () => {
  // const queryClient = useQueryClient();
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.DISBURSE_POINT_SYSTEM],
    mutationFn: (payload: {
      entity_jar: string;
      distribution_amounts: DistributionAmountType[];
    }) => disbursePointSystem($http, payload),
  });
};
interface Receiver {
  receiver_shukran_id: string;
  amount: number;
}

interface PaymentData {
  receivers: Receiver[];
  sender: string;
  method: string;
  currency: string;
  charge_tipper: boolean;
  // customer?: string;
  // payment_method?: string;
  // setup_future_usage?: boolean;
}

const sendTips = async (
  $http: AxiosInstance,
  payload: PaymentData
): Promise<any> => {
  const apiUrl = constructApiUrl(`/api/v1/tips/`);
  try {
    const response = await $http.post(apiUrl, payload);

    return response;
  } catch (error:any) {
    const erroreMsg= error?.response?.data?.errors[0]?.msg||"Failed to send tips";
    throw new Error(erroreMsg);
  }
};

export const useSendTips = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.SEND_TIPS],
    mutationFn: (options: PaymentData) => sendTips($http, options),
  });
};

type CalculateTipChangeType = {
  amount: string;
  currency: string;
};

const calculateTipChange = async (
  $http: AxiosInstance,
  options: CalculateTipChangeType[]
) => {
  const apiUrl = constructApiUrl(`/api/v1/tips/calculate-tip-charge/`);
  
  try {

    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error) {
    throw new Error("Failed to calculate charge");
  }
};

export const useCalculateTipCharge = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.CALCULATE_TIP_CHARGE],
    mutationFn: (options: CalculateTipChangeType[]) =>
      calculateTipChange($http, options),
  });
};

const checkMpesaTransactionStatus = async (
  $http: AxiosInstance,
  transactionId: string
): Promise<any> => {
  const apiUrl = constructApiUrl(
    `transactions/status/?transaction_id=${transactionId}`
  );
  try {
    const response = await $http.get(apiUrl);

    return response;
  } catch (error) {
    console.error("Error checking M-Pesa transaction status:", error);
    return error;
  }
};

export const useCheckMpesaTransactionStatus = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarGroupsQueryTypes.CHECK_MPESA_TRANSACTION_STATUS],
    mutationFn: (transactionId: string) =>
      checkMpesaTransactionStatus($http, transactionId),
  });
};
