export const useFirstTimeTipperQueryTypes={

    SIGN_UP_USER:'signUpUser',
    REQUEST_OTP:"requestOTP", 
    SUBMIT_OTP:"submitOTP",
    GET_WITHDRAW_BALANCE:"getWithdrawBalance",
    ACTIVATE_SACCO:"activateSacco",
    WITHDRAW_FUNDS:"withdrawFunds",
    GET_PROCESSING_BALANCE:"getProcessingBalance",
    REQUEST_MPESA_SELF_TIP:"requestMpesaSelfTip",
    FETCH_PROFILE:"fetchProfile"

}