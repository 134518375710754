import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import ShukranTagLine from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../components-shadcn/ui/input";
import BackButton from '../globals/BackButton';
import { useRequestOTP, useSignUpUser } from './state/server-state/useFirstTimeTipServerState';
import { toast } from 'sonner';
import useIsIOS from '../popups/useIsIos';
import { useFirstTimeTipState } from './state/local-state/useFirstTimeTipLocalState';

const ConfirmPasswordInitial = () => {
  const navigate = useNavigate();
  const [isIOS, setIsIOS] = useIsIOS();
  const { userData, firstName, lastName, setPassword,setUserData } = useFirstTimeTipState();
  const requestOTP = useRequestOTP();
  const signUpUser = useSignUpUser();
  
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  
    const handleSave = (phone, token) => {
      // Save phone to state
      setUserData({ phone, token });    
    };

  // Redirect if required data is missing
  useEffect(() => {
    if (!userData?.phone || !firstName || !lastName) {
      navigate('/first-time-tip');
    }
  }, [userData?.phone, firstName, lastName, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Validate passwords match in real-time
  useEffect(() => {
    if (formData.confirmPassword && formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  }, [formData.password, formData.confirmPassword]);

  const handleRequestOTP = async (payload) => {
    try {
      await requestOTP.mutateAsync(payload);
      toast.success("OTP sent successfully");
      navigate('/otp-initial');
    } catch (error) {
      console.error("Request OTP error:", error);
      toast.error("Failed to send OTP");
    }
  };

  const handleSignUpUser = async () => {
    if (isLoading || !isFormValid) return;

    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone: userData?.phone,
      password: formData.password,
      user_type: "SERVICE WORKER",
      is_sacco: "False",
      platform: isIOS.platform || "",
      device: isIOS.device || "",
    };
    setPassword(formData.password)

    try {
      const response = await signUpUser.mutateAsync(payload);
      const data = response?.data?.data;
      
      if (data) {
        const { token, phone } = data;
        const otpPayload = {
          phone,
          token
        };
        handleSave(phone, token)
        await handleRequestOTP(otpPayload);
      }

    } catch (error) {
      toast.error(`${error}`);
      console.error("User sign up failed:", error);
    }
  };

  const isLoading = signUpUser.isLoading || requestOTP.isLoading;
  const isFormValid = formData.password && 
                     formData.confirmPassword && 
                     formData.password === formData.confirmPassword;

  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full px-4">
        <BackButton onPress={()=>navigate(-1)} />
      </div>

      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranTagLine} alt="Shukran Logo" className="w-28" />
      </div>

      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <h2 className="text-[#50A2A7] text-xl font-medium mb-6">
          Create a secure password
        </h2>

        <div className="space-y-6">
          <div className="space-y-2">
            <label className="text-gray-600 text-sm">Password</label>
            <div className="relative">
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="h-12 pr-10"
                placeholder="********"
                disabled={isLoading}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                disabled={isLoading}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-gray-600 text-sm">Confirm password</label>
            <div className="relative">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={`h-12 pr-10 ${passwordError ? 'border-red-500' : ''}`}
                placeholder="********"
                disabled={isLoading}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                disabled={isLoading}
              >
                {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
            {passwordError && (
              <p className="text-red-500 text-sm">{passwordError}</p>
            )}
          </div>

          <button 
            onClick={handleSignUpUser}
            disabled={!isFormValid || isLoading}
            className={`w-full flex justify-center items-center gap-2 h-[60px] rounded-full text-white font-medium transition-colors ${
              isFormValid && !isLoading 
                ? 'bg-[#F2C773] hover:bg-[#F2C773]/90' 
                : 'bg-gray-300 cursor-not-allowed'
            }`}
          >
            {isLoading ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                {requestOTP.isLoading ? 'SENDING OTP...' : 'CREATING ACCOUNT...'}
              </>
            ) : (
              'ACTIVATE ACCOUNT'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPasswordInitial;