import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShukranTagLine from "../../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../../components-shadcn/ui/input";
import { Label } from '../../../components-shadcn/ui/label';
import { Button } from '../../../components-shadcn/ui/button';
import { Loader2, CheckCircle2, XCircle } from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../../../components-shadcn/ui/dialog";
import { useActivateSacco } from '../state/server-state/useFirstTimeTipServerState';
import { useFirstTimeTipState } from '../state/local-state/useFirstTimeTipLocalState';
import { toast } from 'sonner';

const AgreeToTerms = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const {
        firstName,
        lastName,
        email,
        nationalId,
        dateOfBirth,
        userData
    } = useFirstTimeTipState();

    console.log("USER dATA ", userData)

    const activateSacco = useActivateSacco();
    const isActivatingSacco = activateSacco.isLoading;

    const handleActivateSacco = async (e) => {
        e.preventDefault();
        
        const payload = {
            national_id_passport: nationalId,
            phone_number: userData?.phone || '',
            first_name: firstName,
            surname: lastName,
            email: email,
            date_of_birth: dateOfBirth,
            kyc_stage: 6
        };

        try {
            await activateSacco.mutateAsync(payload);
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
                navigate('/withdraw-balance-initial');
            }, 3000);
        } catch (error) {
            setErrorMessage(error.message || 'Failed to activate account. Please try again.');
            setShowError(true);
        }
    };

    return (
        <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
            <div className="w-full flex justify-start mt-8 mb-16 p-3">
                <img src={ShukranTagLine} alt="Shukran Logo" className="w-32" />
            </div>

            <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
                <h1 className="text-[#44CBBE] text-2xl font-medium mb-6">Agree to Terms of Use</h1>
                
                <p className="text-gray-700 mb-8 leading-relaxed">
                    I declare that all the particulars given by me are true. I confirm that I have read the terms and conditions governing the opening, operating and closure of my account under Shukran Finance Platform Limited and agree to be bound by them. I further unequivocally consent that my personal data, collected in connection with such terms and conditions, may from time to time be used and disclosed for such lawful purposes and to such persons as may be in accordance with the Shukran Finance Platform's prevailing Privacy Policy, and the relevant laws, as amended from time to time. View our{" "}
                    <a href="#" className="underline font-medium">Terms of Use</a> and{" "}
                    <a href="#" className="underline font-medium">Data Privacy</a> to learn more.
                </p>

                <form onSubmit={handleActivateSacco} className="space-y-6">
                    <div className="space-y-2">
                        <Label className="text-sm text-gray-500">Enter your full name to agree to the above</Label>
                        <Label className="text-xs text-gray-400">Full name</Label>
                        <Input
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="bg-[#F5F6F8] border-[#CCD3DB]"
                            placeholder="Jane Doe"
                        />
                    </div>

                    <Button 
                        type="submit"
                        disabled={!fullName.trim() || isActivatingSacco}
                        className="w-full bg-[#F2C773] hover:bg-[#E5BA66] text-white rounded-full py-6 text-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {isActivatingSacco ? (
                            <>
                                <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                                ACTIVATING ACCOUNT...
                            </>
                        ) : (
                            'SAVE & COMPLETE'
                        )}
                    </Button>
                </form>
            </div>

            {/* Success Dialog */}
            <Dialog open={showSuccess} onOpenChange={setShowSuccess}>
                <DialogContent className="text-center p-6 max-w-sm">
                    <DialogHeader>
                        <DialogTitle className="text-center mb-2">
                            <div className="flex justify-center mb-4">
                                <CheckCircle2 className="h-16 w-16 text-[#44CBBE]" />
                            </div>
                            Account Activated Successfully!
                        </DialogTitle>
                    </DialogHeader>
                    <p className="text-gray-500 text-center">
                        Your verification has been sent for approval. Once approved in 2 working days, you will receive your tip to your M-PESA
                    </p>
                </DialogContent>
            </Dialog>

            {/* Error Dialog */}
            <Dialog open={showError} onOpenChange={setShowError}>
                <DialogContent className="text-center p-6 max-w-sm">
                    <DialogHeader>
                        <DialogTitle className="text-center mb-2">
                            <div className="flex justify-center mb-4">
                                <XCircle className="h-16 w-16 text-red-500" />
                            </div>
                            Activation Failed
                        </DialogTitle>
                    </DialogHeader>
                    <p className="text-gray-500 text-center">
                        {errorMessage}
                    </p>
                    <Button 
                        onClick={() => setShowError(false)}
                        className="mt-4 bg-red-500 hover:bg-red-600 text-white"
                    >
                        Close
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AgreeToTerms;