import React from 'react';
import { Check } from 'lucide-react';

const OnboardingSteps = ({ currentStep }) => {
  const steps = [
    { 
      number: 1, 
      title: 'Create Departments', 
      description: 'Set up your team structure',
      status: currentStep > 0 ? 'Completed' : currentStep === 0 ? 'In Progress' : 'Pending'
    },
    { 
      number: 2, 
      title: 'Add Members', 
      description: 'Add your team members',
      status: currentStep > 1 ? 'Completed' : currentStep === 1 ? 'In Progress' : 'Pending'
    },
    { 
      number: 3, 
      title: 'Settings', 
      description: 'Configure your preferences',
      status: currentStep > 2 ? 'Completed' : currentStep === 2 ? 'In Progress' : 'Pending'
    }
  ];

  const getStepColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-[#10B981]'; // Emerald-500 equivalent
      case 'In Progress':
        return 'bg-[#3B82F6]'; // Blue-600 equivalent
      default:
        return 'bg-gray-200';
    }
  };

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-[#D1FAE5] text-[#059669]'; // Light green bg, darker green text
      case 'In Progress':
        return 'bg-[#DBEAFE] text-[#2563EB]'; // Light blue bg, darker blue text
      default:
        return 'bg-gray-100 text-gray-500';
    }
  };

  return (
    <div className="w-full px-4 py-6">
      <div className="flex items-center justify-between relative">
        {/* Connecting Line */}
        <div className="absolute left-0 top-5 w-full h-1 bg-gray-200">
          <div 
            className="h-full transition-all duration-300"
            style={{
              width: `${(currentStep / (steps.length - 1)) * 100}%`,
              backgroundColor: '#10B981'
            }}
          />
        </div>

        {/* Steps */}
        {steps.map((step, index) => (
          <div key={step.number} className="relative flex flex-col items-center">
            {/* Circle */}
            <div 
              className={`w-10 h-10 rounded-full flex items-center justify-center z-10 transition-colors duration-300 ${getStepColor(step.status)}`}
            >
              {step.status === 'Completed' ? (
                <Check className="w-5 h-5 text-white" />
              ) : (
                <span className="text-white font-medium">{step.number}</span>
              )}
            </div>

            {/* Title */}
            <h3 className="mt-3 text-sm font-semibold text-gray-900 text-center">
              {step.title}
            </h3>

            {/* Description */}
            <p className="mt-1 text-xs text-gray-500 max-w-[100px] text-center">
              {step.description}
            </p>

            {/* Status Badge */}
            <span 
              className={`mt-2 px-2 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(step.status)}`}
            >
              {step.status}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnboardingSteps;