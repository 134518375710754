import {
    useQuery,
    useQueryClient,
    UseMutationResult,
    UseQueryResult,
    useMutation,
  } from "@tanstack/react-query";

import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { tipjarSettingsQueryTypes } from "./tipjarSettingsQueryTypes";


type UpdateEntity={
    entityId?:string, 
    allow_individual_tips_in_group: boolean,
    jar_members_cannot_receive_individual_tip: boolean,
    allow_default_tipjar: boolean,
    allow_individual_tips: boolean,
    main_tipjar_enabled: boolean,
    subtipjars_enabled: boolean,
    tipjar_and_individuals: boolean,
    individuals_enabled: boolean
}
const updateEntity = async ($http: AxiosInstance, payload: UpdateEntity) => {
    const {entityId}=payload
    const apiUrl = constructApiUrl(`/tipjar/entity/${entityId}/update-entity/`);
    try {
      delete payload.entityId;
      const data=payload
      const response = await $http.post(apiUrl, data);
      return response;
    } catch (error:any) {
        const errorMsg=error?.response?.data?.detail||error?.response?.data?.data||"An unexpected error occurred";
     
      throw new Error(errorMsg);
    }
  };
export const useUpdateEntity = () => {
    const queryClient = useQueryClient();
    const $http = useAxios();

    return useMutation({
      mutationKey: [tipjarSettingsQueryTypes.UPDATE_TIPJAR_SETTINGS],
      mutationFn: (payload: UpdateEntity) => updateEntity($http, payload),
    //   onSuccess: (data, variables, context) => {
    //     queryClient.invalidateQueries([tipjarSettingsQueryTypes.UPDATE_ENTITY]);
    //   },
    //   onError: (error) => {
    //     throw new Error("An unexpected error occurred");
    //   },
    });
  };