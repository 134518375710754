import React, { useState } from 'react';
import { ChevronLeft, CirclePlus } from 'lucide-react';
import { Input } from '../../../components-shadcn/ui/input';
import { Button } from '../../../components-shadcn/ui/button';
import { Checkbox } from '../../../components-shadcn/ui/checkbox';
import { PhoneInput } from '../../../components-shadcn/ui/phone-input';
import { useTipJarLocalState } from '../../Tipjar/state/local-state/useTipjarLocalState';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components-shadcn/ui/select';
import { toast } from 'sonner';

const AddWithPhone = ({ onBack, onSwitchToShukran, onMemberAdd }) => {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    firstName: '',
    lastName: '',
    department_id: '',
    can_join_entity_jar: true
  });

  const { entityDepartments } = useTipJarLocalState();
  const activeDepartments = entityDepartments?.filter(dept => dept.active) || [];

  const validateForm = () => {
    if (!formData.phoneNumber) {
      toast.error("Phone number is required");
      return false;
    }

    if (!formData.firstName.trim()) {
      toast.error("First name is required");
      return false;
    }

    if (!formData.lastName.trim()) {
      toast.error("Last name is required");
      return false;
    }

    if (!formData.department_id) {
      toast.error("Please select a department");
      return false;
    }

    // Validate phone number format (should start with 254)
    const cleanPhone = formData.phoneNumber.toString().replace(/\D/g, '');
    if (!cleanPhone.startsWith('254')) {
      toast.error("Phone number must start with 254 (Kenya)");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
  
    const memberData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      phone: formData.phoneNumber.toString().substring(1),
      department_id: formData.department_id,
      can_join_entity_jar: formData.can_join_entity_jar
    };
  
    onMemberAdd(memberData);
  };

  return (
    <div className="flex flex-col min-h-screen bg-white px-4 pt-4">
      <div className='w-full flex flex-row items-center gap-6 mb-6'>
        <button 
          onClick={onBack}
          className="flex items-center text-gray-600 w-fit"
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <p className='text-lg font-semibold'>Add with Phone number</p>
      </div>

      <div className="space-y-4 mb-6">
        <div className="relative">
          <PhoneInput
            placeholder="Phone number"
            defaultCountry="KE"
            value={formData.phoneNumber}
            onChange={(value) => setFormData(prev => ({ ...prev, phoneNumber: value }))}
            className="h-12"
          />
          <p className="text-xs text-gray-500 mt-1">Enter phone number starting with 254</p>
        </div>

        <Input
          value={formData.firstName}
          onChange={(e) => setFormData(prev => ({ ...prev, firstName: e.target.value }))}
          placeholder="First name"
          className="h-12"
        />

        <Input
          value={formData.lastName}
          onChange={(e) => setFormData(prev => ({ ...prev, lastName: e.target.value }))}
          placeholder="Last name"
          className="h-12"
        />

        <Select
          value={formData.department_id}
          onValueChange={(value) => setFormData(prev => ({ ...prev, department_id: value }))}
        >
          <SelectTrigger className="h-12">
            <SelectValue placeholder="Select department" />
          </SelectTrigger>
          <SelectContent>
            {activeDepartments.map((department) => (
              <SelectItem key={department.id} value={department.id}>
                {department.name} ({department.distribution_points} points)
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="groupTips"
            checked={formData.can_join_entity_jar}
            onCheckedChange={(checked) => 
              setFormData(prev => ({ ...prev, can_join_entity_jar: checked }))
            }
            className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
          />
          <label htmlFor="groupTips" className="text-sm text-gray-600">
            Include member in group tips
          </label>
        </div>
      </div>

      <div className="py-4 flex flex-row items-center text-sm">
        <span className="text-primary">OR</span>
        <button
          onClick={onSwitchToShukran}
          className="border-none rounded-md px-1 py-1 bg-[#64C8CE] text-white ml-2 cursor-pointer"
        >
          add with Shukran ID
        </button>
      </div>

      <Button
        onClick={handleSubmit}
        className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white h-14 rounded-full"
      >
        PROCEED
      </Button>
    </div>
  );
};

export default AddWithPhone;