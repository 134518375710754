import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertTriangle, Loader2 } from 'lucide-react';
import ShukranTagLine from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";
import { Alert, AlertDescription, AlertTitle } from '../../components-shadcn/ui/alert';
import BackButton from "../globals/BackButton";
import { useFirstTimeTipState } from './state/local-state/useFirstTimeTipLocalState';
import { useRequestOTP, useSignUpUser, useSubmitOTP } from './state/server-state/useFirstTimeTipServerState';
import { useSignInUser } from '../Login/state/server-state/loginServerState';
import { saveToSessionStorage } from '../../Services/Storage';
import useIsIOS from "../popups/useIsIos";
import { toast } from "sonner";

const OTPInitial = () => {
  const navigate = useNavigate();
  const { userData, password, clearState } = useFirstTimeTipState();
  const [isIOS, setIsIOS] = useIsIOS();
  const requestOTP = useRequestOTP();
  const submitOTP = useSubmitOTP();
  const signInUser = useSignInUser();
  const isSigningIn = signInUser.isLoading;
  const isResendingOtp = requestOTP.isLoading;
  const isSubmittingOTP = submitOTP.isLoading;
  console.log("user DATA ", userData)
  const handleRequestOTP = async () => {
   
    if (!userData || !userData?.phone || !userData?.token) {
      return;
    }
    const payload = {
      phone: userData.phone,
      token: userData.token
    };
    try {
      await requestOTP.mutateAsync(payload);
      toast.success("OTP requested successfully");
    } catch (error) {
      console.error("Request OTP error:", error);
      toast.error("Failed to request OTP");
    }
  };

  const handleSubmitOTP = async (otpValue) => {
    if (!userData || !userData?.phone || !userData?.token) {
      return;
    }
    const payload = {
      phone: userData.phone,
    //   token: userData.token,
      nonce: parseInt(otpValue) // Convert OTP string to number
    };

    try {
      await submitOTP.mutateAsync(payload);
      toast.success("OTP verified successfully");
      await handleSignInAfterComplete();
    } catch (error) {
      console.error("Submit OTP error:", error);
      toast.error("Invalid OTP. Please try again.");
    }
  };

  const handleSignInAfterComplete = async () => {
    const signInPayload = {
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
      phone: userData?.phone || "",
      password: password || "",
    };

    try {
      const response = await signInUser.mutateAsync(signInPayload);

      if (!response) {
        toast.error("Sign in failed");
        return;
      }
      if (!response?.token || !response?.shukran_id || !response?.phone) {
        toast.error("Invalid data found");
        return;
      }

      await saveToSessionStorage("token", response.token);
      await saveToSessionStorage("ShukranId", response.shukran_id);
      await saveToSessionStorage("firstName", response.first_name);
      await saveToSessionStorage("phoneNumber", response.phone);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("origin", false);
      navigate('/withdraw-balance-initial');
    } catch (error) {
      toast.error("An unexpected error occurred");
      console.log("LOGIN ERROR", error);
    }
  };

  // Redirect if no phone number
  useEffect(() => {
    if (!userData?.phone) {
      navigate('/first-time-tip');
    }
  }, [userData?.phone, navigate]);

  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full px-4">
        <BackButton onPress={() => navigate(-1)} />
      </div>
      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranTagLine} alt="Shukran Logo" className="w-28" />
      </div>
      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <OTPComponent 
          phone={userData?.phone}
          onRequestOTP={handleRequestOTP}
          onSubmitOTP={handleSubmitOTP}
          isResendingOtp={isResendingOtp}
          isSubmittingOTP={isSubmittingOTP}
          isSigningIn={isSigningIn}
        />
      </div>
    </div>
  );
};

const OTPComponent = ({ 
  phone, 
  onRequestOTP, 
  onSubmitOTP,
  isResendingOtp,
  isSubmittingOTP,
  isSigningIn
}) => {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [timer, setTimer] = useState(60);
  const [isResendActive, setIsResendActive] = useState(false);
  const [error, setError] = useState('');
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    if (timer > 0 && !isResendActive) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setIsResendActive(true);
    }
  }, [timer, isResendActive]);

  const handleChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setError('');

      if (value && index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleResend = async () => {
    if (isResendActive && !isResendingOtp) {
        console.log("HANDLE RESET ", isResendActive)
      try {
        await onRequestOTP();
        setTimer(60);
        setIsResendActive(false);
        setError('');
      } catch (error) {
        console.log("OTP ERROR ", error)
        // Error handling is done in parent component
      }
    }else{
        console.log("HANDLE RESET ", isResendActive)
    }
  };

  const handleVerifyOTP = async () => {
    const otpValue = otp.join('');
    if (otpValue.length !== 4) {
      setError('Please enter all 4 digits');
      return;
    }

    try {
      await onSubmitOTP(otpValue);
    } catch (error) {
      // Error handling is done in parent component
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const isLoading = isSubmittingOTP || isSigningIn;
  const getButtonText = () => {
    if (isSubmittingOTP) return "VERIFYING OTP...";
    if (isSigningIn) return "SIGNING IN...";
    return "PROCEED";
  };

  return (
    <div className="flex flex-col w-full gap-6">
      <h2 className="text-[#50A2A7] text-xl font-medium mb-4">
        Verify phone number
      </h2>

      <p className="text-gray-600 text-sm mb-6">
        A 4 digit code has been sent to the number {phone}
      </p>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertTriangle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="flex gap-6 mb-4 w-full flex-row items-center justify-center">
        {otp.map((digit, index) => (
          <Input
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className={`w-14 h-14 text-center text-2xl border-2 rounded-lg focus:border-[#50A2A7] focus:ring-[#50A2A7] shadow-md ${
              error ? 'border-red-500' : ''
            }`}
          />
        ))}
      </div>

      <div className="flex items-center gap-1 mb-12">
        <p className="text-gray-600 text-sm">Didn't receive code?</p>
        <button
          onClick={handleResend}
          className={`text-sm flex items-center ${isResendActive ? 'text-[#50A2A7]' : 'text-gray-400'}`}
          disabled={!isResendActive || isResendingOtp}
        >
          {isResendingOtp ? (
            <>
              <Loader2 className="animate-spin h-4 w-4 mr-2" />
              Sending...
            </>
          ) : (
            `Resend in ${formatTime(timer)}`
          )}
        </button>
      </div>

      <Button
        onClick={handleVerifyOTP}
        className="w-full bg-[#F2C773] hover:bg-[#F2C773]/90 text-white h-[60px] rounded-full"
        disabled={isLoading || otp.join('').length !== 4}
      >
        {isLoading && <Loader2 className="animate-spin mr-2 h-4 w-4" />}
        {getButtonText()}
      </Button>
    </div>
  );
};

export default OTPInitial;