import React, { useState, useEffect } from 'react';
import { Switch } from "../../components-shadcn/ui/switch";
import { Checkbox } from "../../components-shadcn/ui/checkbox";
import { Button } from "../../components-shadcn/ui/button";
import { Card } from "../../components-shadcn/ui/card";
import { useTipJarLocalState } from '../Tipjar/state/local-state/useTipjarLocalState';
import { useUpdateEntity } from './state/server-state/useTipjarSettingsServerState';
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from 'react-router-dom';
import { useGetEntitiesMutation } from '../TipGroups/state/server-state/useTipGroupsServerState';

const TippingSettings = () => {
  const [defaultMode, setDefaultMode] = useState(false);
  const [mainTipJarOnly, setMainTipJarOnly] = useState(false);
  const [subTipJarsOnly, setSubTipJarsOnly] = useState(false);
  const [individualsOnly, setIndividualsOnly] = useState(false);
  const [individualsAndMainTipJar, setIndividualsAndMainTipJar] = useState(false);
  const [individualsAndSubTipJars, setIndividualsAndSubTipJars] = useState(false);
  const [enableBlocking, setEnableBlocking] = useState(true);
  const [subTipJARActive, setSubTipJARActive]=useState(true);

  const navigate = useNavigate();
  const { activeEntity, setActiveEntity,entityJARs } = useTipJarLocalState();
  const updateEntity = useUpdateEntity();
  const isUpdatingEntity = updateEntity.isLoading;
  const getEntitiesMutation = useGetEntitiesMutation();

  useEffect(() => {
    if(!entityJARs) return

    const subJARs=entityJARs.filter((jar) => jar.is_default_entity_jar === false);
    if(subJARs && subJARs?.length>0){
      setSubTipJARActive(true);
    }else{
      setSubTipJARActive(false);
    }
  },[entityJARs])

  console.log("Sub tipjar active ", subTipJARActive)

  useEffect(() => {
    if (activeEntity) {
      // Reset all states first
      setDefaultMode(false);
      setMainTipJarOnly(false);
      setSubTipJarsOnly(false);
      setIndividualsOnly(false);
      setIndividualsAndMainTipJar(false);
      setIndividualsAndSubTipJars(false);

      // Set enableBlocking based on jar_members_can_receive_individual_tip
      setEnableBlocking(activeEntity.jar_members_can_receive_individual_tip);

      // Determine which mode to set based on activeEntity properties
      if (activeEntity.main_tipjar_enabled && 
          activeEntity.subtipjars_enabled && 
          activeEntity.individuals_enabled) {
        setDefaultMode(true);
      } 
      else if (activeEntity.main_tipjar_enabled && 
               !activeEntity.subtipjars_enabled && 
               !activeEntity.individuals_enabled) {
        setMainTipJarOnly(true);
      }
      else if (!activeEntity.main_tipjar_enabled && 
               activeEntity.subtipjars_enabled && 
               !activeEntity.individuals_enabled) {
        setSubTipJarsOnly(true);
      }
      else if (!activeEntity.main_tipjar_enabled && 
               !activeEntity.subtipjars_enabled && 
               activeEntity.individuals_enabled) {
        setIndividualsOnly(true);
      }
      else if (activeEntity.main_tipjar_enabled && 
               !activeEntity.subtipjars_enabled && 
               activeEntity.individuals_enabled) {
        setIndividualsAndMainTipJar(true);
      }
      else if (!activeEntity.main_tipjar_enabled && 
               activeEntity.subtipjars_enabled && 
               activeEntity.individuals_enabled) {
        setIndividualsAndSubTipJars(true);
      }
    }
  }, [activeEntity]);

  const handleSwitchChange = (mode) => {
    // Reset all switches
    setDefaultMode(false);
    setMainTipJarOnly(false);
    setSubTipJarsOnly(false);
    setIndividualsOnly(false);
    setIndividualsAndMainTipJar(false);
    setIndividualsAndSubTipJars(false);

    // Set the selected mode
    switch(mode) {
      case 'default':
        setDefaultMode(true);
        break;
      case 'mainTipJar':
        setMainTipJarOnly(true);
        break;
      case 'subTipJars':
        setSubTipJarsOnly(true);
        break;
      case 'individuals':
        setIndividualsOnly(true);
        break;
      case 'individualsMain':
        setIndividualsAndMainTipJar(true);
        break;
      case 'individualsSub':
        setIndividualsAndSubTipJars(true);
        break;
    }
  };

  const generatePayload = () => {
    if (defaultMode) {
      return {
        allow_individual_tips_in_group: true,
        jar_members_can_receive_individual_tip: enableBlocking,
        allow_default_tipjar: true,
        allow_individual_tips: true,
        main_tipjar_enabled: true,
        subtipjars_enabled: true,
        tipjar_and_individuals: true,
        individuals_enabled: true
      };
    } else if (mainTipJarOnly) {
      return {
        allow_individual_tips_in_group: false,
        jar_members_can_receive_individual_tip: false,
        allow_default_tipjar: true,
        allow_individual_tips: false,
        main_tipjar_enabled: true,
        subtipjars_enabled: false,
        tipjar_and_individuals: false,
        individuals_enabled: false
      };
    } else if (subTipJarsOnly) {
      return {
        allow_individual_tips_in_group: false,
        jar_members_can_receive_individual_tip: false,
        allow_default_tipjar: true,
        allow_individual_tips: false,
        main_tipjar_enabled: false,
        subtipjars_enabled: true,
        tipjar_and_individuals: false,
        individuals_enabled: false
      };
    } else if (individualsOnly) {
      return {
        allow_individual_tips_in_group: false,
        jar_members_can_receive_individual_tip: true,
        allow_default_tipjar: true,
        allow_individual_tips: true,
        main_tipjar_enabled: false,
        subtipjars_enabled: false,
        tipjar_and_individuals: false,
        individuals_enabled: true
      };
    } else if (individualsAndMainTipJar) {
      return {
        allow_individual_tips_in_group: true,
        jar_members_can_receive_individual_tip: enableBlocking,
        allow_default_tipjar: true,
        allow_individual_tips: true,
        main_tipjar_enabled: true,
        subtipjars_enabled: false,
        tipjar_and_individuals: true,
        individuals_enabled: true
      };
    } else if (individualsAndSubTipJars) {
      return {
        allow_individual_tips_in_group: true,
        jar_members_can_receive_individual_tip: enableBlocking,
        allow_default_tipjar: true,
        allow_individual_tips: true,
        main_tipjar_enabled: false,
        subtipjars_enabled: true,
        tipjar_and_individuals: true,
        individuals_enabled: true
      };
    }
  };

  const handleSave = async () => {
    if (!activeEntity) {
      toast.error("Entity information not found");
      return;
    }

    const payload = {
      entityId: activeEntity.id,
      ...generatePayload()
    };
    console.log("PAYLOAD ", payload)

    try {
      await updateEntity.mutateAsync(payload);
      const data = await getEntitiesMutation.mutateAsync();
      if (data?.data?.results) {
        const currEntity = data.data.results.find(e => e.id === activeEntity.id);
        if (currEntity) {
          setActiveEntity(currEntity);
        }
      }
      toast.success("Settings updated successfully");
    } catch (error) {
      toast.error(error?.toString());
    }
  };

  const showBlockingSetting = defaultMode || individualsAndMainTipJar || individualsAndSubTipJars;

  return (
    <div className="p-3 max-w-2xl mx-auto">
      <h1 className="text-2xl font-bold mb-6 w-full">Settings</h1>
      
      <Card className="p-4 mb-4">
        <h2 className="text-lg font-semibold mb-4">Tipping Mode</h2>
        
        <div className="space-y-6">
          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="font-medium">Default</label>
              <Switch
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                checked={defaultMode}
                onCheckedChange={() => handleSwitchChange('default')}
              />
            </div>
            <p className="text-sm text-gray-600">
              Guests can tip the Main TipJAR, Sub TipJARs and Individuals
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="font-medium">Main TipJAR Only</label>
              <Switch
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                checked={mainTipJarOnly}
                onCheckedChange={() => handleSwitchChange('mainTipJar')}
              />
            </div>
            <p className="text-sm text-gray-600">
              Guests can only tip the Main TipJAR
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="font-medium">Sub TipJARs Only</label>
              <Switch
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                checked={subTipJarsOnly}
                disabled={!subTipJARActive}
                onCheckedChange={() => handleSwitchChange('subTipJars')}
              />
            </div>
            <p className="text-sm text-gray-600">
              Guests can only tip Sub TipJARs
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="font-medium">Individuals Only</label>
              <Switch
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                checked={individualsOnly}
                onCheckedChange={() => handleSwitchChange('individuals')}
              />
            </div>
            <p className="text-sm text-gray-600">
              Guests can only tip Individual members
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="font-medium">Individuals and Main TipJAR Only</label>
              <Switch
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                checked={individualsAndMainTipJar}
                onCheckedChange={() => handleSwitchChange('individualsMain')}
              />
            </div>
            <p className="text-sm text-gray-600">
              Guests can tip Individuals and the Main TipJAR
            </p>
          </div>

          <div>
            <div className="flex items-center justify-between mb-2">
              <label className="font-medium">Individuals and Sub TipJARs Only</label>
              <Switch
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                checked={individualsAndSubTipJars}
                onCheckedChange={() => handleSwitchChange('individualsSub')}
              />
            </div>
            <p className="text-sm text-gray-600">
              Guests can tip Individuals and the Sub TipJARs
            </p>
          </div>
        </div>

        {showBlockingSetting && (
          <div className="mt-6 pt-4 border-t">
            <div className="flex items-start space-x-3">
              <Checkbox 
                id="blocking"
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1] mt-1"
                checked={enableBlocking}
                onCheckedChange={(val)=>setEnableBlocking(!val)}
              />
              <div>
                <label htmlFor="blocking" className="font-medium">Enable Blocking</label>
                <p className="text-sm text-gray-600">
                  Guests cannot tip individual members who are part of a TipJAR
                </p>
              </div>
            </div>
          </div>
        )}
      </Card>

      <Button 
        onClick={handleSave}
        disabled={isUpdatingEntity}
        className="w-fit bg-[#F2C773] hover:bg-[#F2C773]/90 text-white h-[40px] rounded-md"
      >
        {isUpdatingEntity ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            SAVING...
          </>
        ) : (
          'SAVE'
        )}
      </Button>
    </div>
  );
};

export default TippingSettings;