import React, { useState } from 'react';
import OnboardingSteps from "./WizzardStepper"
import { useNavigate } from 'react-router-dom';
import DepartmentSetup from './DepartmentSetup';
import IndividualSetup from './IndividualSetup';
import { ChevronLeft, ChevronRight, Check } from 'lucide-react';
import ShukranTagLine from "../../../assets/Logos/shukran-logo-with-tagline-light.png";
import TippingSettings from '../../TipjarSettings/TipjarSettings';

const OnboardingContainer = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [departmentView, setDepartmentView] = useState('main'); // 'main' or 'new'
  const [individualView, setIndividualView] = useState('main'); // 'main', 'phone', 'shukran', 'confirm'
  const navigate = useNavigate();

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFinish = () => {
    navigate('/tipjar/');
  };

  // Helper function to determine button visibility
  const shouldShowNavigationButtons = () => {
    if (currentStep === 0) {
      return departmentView === 'main';
    } else if (currentStep === 1) {
      return individualView === 'main';
    }
    return true; // Always show for settings step
  };

  // Render current step content
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return <DepartmentSetup onViewChange={(view) => setDepartmentView(view)} />;
      case 1:
        return <IndividualSetup onViewChange={(view) => setIndividualView(view)} />;
      case 2:
        return <div className='min-h-screen bg-white pb-[150px]'> <TippingSettings/></div>
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-imag-dash-2 w-full flex flex-col">
      <div className="w-full flex justify-center mt-8 mb-8">
        <img src={ShukranTagLine} alt="Shukran Logo" className="w-28" />
      </div>

      <div className="h-full bg-white relative w-full">
        <OnboardingSteps currentStep={currentStep} />

        {/* Content */}
        <div className="px-4">
          {renderStepContent()}
        </div>

        {/* Navigation Buttons */}
        {shouldShowNavigationButtons() && (
          <div className="fixed bottom-6 w-full px-6 flex justify-between items-center max-w-[600px]">
            {currentStep > 0 && (
              <button
                onClick={handleBack}
                className="flex items-center space-x-2 bg-white border border-[#50A2A7] text-[#50A2A7] px-6 py-3 rounded-full shadow-lg hover:bg-gray-50 transition-colors"
              >
                <ChevronLeft size={20} />
                <span>Back</span>
              </button>
            )}
            
            {currentStep < 2 ? (
              <button
                onClick={handleNext}
                className="flex items-center space-x-2 bg-[#F2C773] text-white px-6 py-3 rounded-full shadow-lg hover:bg-[#e0b665] transition-colors ml-auto"
              >
                <span>Next</span>
                <ChevronRight size={20} />
              </button>
            ) : (
              <button
                onClick={handleFinish}
                className="flex items-center space-x-2 bg-[#F2C773] text-white px-6 py-3 rounded-full shadow-lg hover:bg-[#e0b665] transition-colors"
              >
                <span>Finish</span>
                <Check size={20} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingContainer;