import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ShukranTagLine from "../../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../../components-shadcn/ui/input";
import { Label } from '../../../components-shadcn/ui/label';
import { Button } from '../../../components-shadcn/ui/button';
import { ArrowRight } from 'lucide-react';
import { useFirstTimeTipState } from '../state/local-state/useFirstTimeTipLocalState';
import { toast } from 'sonner';


const VerifyIdentity = () => {
    const navigate = useNavigate();
    const {
        firstName, lastName, email, nationalId, dateOfBirth, workplace,
        identityErrors, setIdentityField
    } = useFirstTimeTipState();

    // Check if form is valid (all fields filled and no errors)
    const isFormValid = useMemo(() => {
        const hasAllFields = firstName && lastName && email && 
                           nationalId && dateOfBirth && workplace;
        const hasNoErrors = Object.values(identityErrors).every(error => !error);
        return hasAllFields && hasNoErrors;
    }, [firstName, lastName, email, nationalId, dateOfBirth, workplace, identityErrors]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIdentityField(name, value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid) {
            // Proceed to next step
            navigate('/upload-kyc-documents/'); // Replace with actual next route
        } else {
            toast.error("Please fill all fields correctly");
        }
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        } catch {
            return dateString;
        }
    };

    return (
        <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
            <div className="w-full flex justify-start mt-8 mb-16 p-3">
                <img src={ShukranTagLine} alt="Shukran Logo" className="w-32" />
            </div>

            <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
                <h1 className="text-[#44CBBE] text-2xl font-medium mb-3">Verify your identity</h1>
                <p className="text-gray-600 mb-6">
                    These details will be used to verify your Shukran account and make you eligible to receive tips.
                </p>
                
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="text-gray-600 mb-6">Legal details</div>
                    
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-2">
                            <Label htmlFor="firstName" className="text-sm text-gray-500">First name</Label>
                            <Input
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                onChange={handleInputChange}
                                className={`bg-[#F5F6F8] border-[#CCD3DB] ${
                                    identityErrors.firstName ? 'border-red-500' : ''
                                }`}
                            />
                            {identityErrors.firstName && (
                                <p className="text-red-500 text-xs mt-1">{identityErrors.firstName}</p>
                            )}
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="lastName" className="text-sm text-gray-500">Last name</Label>
                            <Input
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={handleInputChange}
                                className={`bg-[#F5F6F8] border-[#CCD3DB] ${
                                    identityErrors.lastName ? 'border-red-500' : ''
                                }`}
                            />
                            {identityErrors.lastName && (
                                <p className="text-red-500 text-xs mt-1">{identityErrors.lastName}</p>
                            )}
                        </div>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="email" className="text-sm text-gray-500">Email Address</Label>
                        <Input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={handleInputChange}
                            className={`bg-[#F5F6F8] border-[#CCD3DB] ${
                                identityErrors.email ? 'border-red-500' : ''
                            }`}
                        />
                        {identityErrors.email && (
                            <p className="text-red-500 text-xs mt-1">{identityErrors.email}</p>
                        )}
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="nationalId" className="text-sm text-gray-500">National ID</Label>
                        <Input
                            id="nationalId"
                            name="nationalId"
                            value={nationalId}
                            onChange={handleInputChange}
                            className={`bg-[#F5F6F8] border-[#CCD3DB] ${
                                identityErrors.nationalId ? 'border-red-500' : ''
                            }`}
                        />
                        {identityErrors.nationalId && (
                            <p className="text-red-500 text-xs mt-1">{identityErrors.nationalId}</p>
                        )}
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="dateOfBirth" className="text-sm text-gray-500">Date of Birth</Label>
                        <Input
                            id="dateOfBirth"
                            name="dateOfBirth"
                            type="date"
                            value={formatDateForInput(dateOfBirth)}
                            onChange={handleInputChange}
                            max={new Date().toISOString().split('T')[0]}
                            className={`bg-[#F5F6F8] border-[#CCD3DB] ${
                                identityErrors.dateOfBirth ? 'border-red-500' : ''
                            }`}
                        />
                        {identityErrors.dateOfBirth && (
                            <p className="text-red-500 text-xs mt-1">{identityErrors.dateOfBirth}</p>
                        )}
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="workplace" className="text-sm text-gray-500">Workplace</Label>
                        <Input
                            id="workplace"
                            name="workplace"
                            value={workplace}
                            onChange={handleInputChange}
                            className={`bg-[#F5F6F8] border-[#CCD3DB] ${
                                identityErrors.workplace ? 'border-red-500' : ''
                            }`}
                        />
                        {identityErrors.workplace && (
                            <p className="text-red-500 text-xs mt-1">{identityErrors.workplace}</p>
                        )}
                    </div>

                    <div className="flex justify-end mt-8">
                        <Button 
                            type="submit" 
                            className={`text-white rounded-full px-8 ${
                                isFormValid 
                                    ? 'bg-[#44CBBE] hover:bg-[#3BB7AA]' 
                                    : 'bg-gray-300 cursor-not-allowed'
                            }`}
                            disabled={!isFormValid}
                        >
                            Next
                            <ArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyIdentity;