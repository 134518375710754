import { create, StateCreator } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  PersistOptions,
} from "zustand/middleware";


interface IdentityData {
    firstName: string;
    lastName: string;
    email: string;
    nationalId: string;
    dateOfBirth: string;
    workplace: string;
  }
  
  interface ValidationErrors {
    firstName: string;
    lastName: string;
    email: string;
    nationalId: string;
    dateOfBirth: string;
    workplace: string;
  }

// Define the types for our user data
interface UserData {
  shukran_id?: string;
  first_name?: string;
  token?: string;
  phone?: string;
  // Add other fields from API response as needed
}

interface FirstTimeTipState extends IdentityData {
    // Existing state
    userData: UserData | null;
    password: string;
    confirmPassword: string;
    firstNameError: string;
    lastNameError: string;
    passwordError: string;
    confirmPasswordError: string;
    
    // New validation errors for identity verification
    identityErrors: ValidationErrors;
    
    // Actions
    setIdentityField: (field: keyof IdentityData, value: string) => void;
    setIdentityError: (field: keyof ValidationErrors, error: string) => void;
    validateIdentityField: (field: keyof IdentityData, value: string) => string;
    clearIdentityData: () => void;
    
    // Existing actions
    setUserData: (data: UserData) => void;
    setPassword: (password: string) => void;
    setConfirmPassword: (password: string) => void;
    clearState: () => void;
  }
  
  const initialIdentityState: IdentityData = {
    firstName: "",
    lastName: "",
    email: "",
    nationalId: "",
    dateOfBirth: "",
    workplace: ""
  };
  
  const initialValidationErrors: ValidationErrors = {
    firstName: "",
    lastName: "",
    email: "",
    nationalId: "",
    dateOfBirth: "",
    workplace: ""
  };
  
  export const useFirstTimeTipState = create<FirstTimeTipState>()(
    persist(
      (set, get) => ({
        // Initialize identity fields
        ...initialIdentityState,
        identityErrors: initialValidationErrors,
        
        // Existing state initialization
        userData: null,
        password: "",
        confirmPassword: "",
        firstNameError: "",
        lastNameError: "",
        passwordError: "",
        confirmPasswordError: "",
  
        validateIdentityField: (field, value) => {
          switch (field) {
            case 'firstName':
            case 'lastName':
              return value.length < 2 ? `${field} must be at least 2 characters` : "";
              
            case 'email':
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              return !emailRegex.test(value) ? "Please enter a valid email address" : "";
              
            case 'nationalId':
              return value.length < 6 ? "ID number must be at least 6 characters" : "";
              
            case 'dateOfBirth':
              try {
                const dob = new Date(value);
                const today = new Date();
                let age = today.getFullYear() - dob.getFullYear();
                const monthDiff = today.getMonth() - dob.getMonth();
                
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
                  age--;
                }
                
                return age < 18 ? "You must be at least 18 years old" : "";
              } catch {
                return "Please enter a valid date";
              }
              
            case 'workplace':
              return value.length < 2 ? "Workplace must be at least 2 characters" : "";
              
            default:
              return "";
          }
        },
  
        setIdentityField: (field, value) => {
          const error = get().validateIdentityField(field, value);
          set((state) => ({
            [field]: value,
            identityErrors: {
              ...state.identityErrors,
              [field]: error
            }
          }));
        },
  
        setIdentityError: (field, error) => 
          set((state) => ({
            identityErrors: {
              ...state.identityErrors,
              [field]: error
            }
          })),
  
        clearIdentityData: () => {
          set({
            ...initialIdentityState,
            identityErrors: initialValidationErrors
          });
        },
  
        // Existing actions remain the same
        setUserData: (data) => set({ userData: data }),
        setPassword: (password) => set({ password }),
        setConfirmPassword: (confirmPassword) => set({ confirmPassword }),
        clearState: () => set({
          ...initialIdentityState,
          identityErrors: initialValidationErrors,
          userData: null,
          password: "",
          confirmPassword: "",
          firstNameError: "",
          lastNameError: "",
          passwordError: "",
          confirmPasswordError: ""
        })
      }),
      {
        name: 'first-time-tip-storage'
      }
    )
  );