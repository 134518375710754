
import React, { useState, useEffect } from 'react';
import { ChevronRight, Search, X } from 'lucide-react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../../components-shadcn/ui/sheet";
import { Input } from "../../components-shadcn/ui/input";
import { ScrollArea } from "../../components-shadcn/ui/scroll-area";
import { cn } from "@/lib/utils";
import { Us } from "react-flags-select";
import { currencies } from './ListOfCurrencies';
import { useTipJarLocalState } from '../Tipjar/state/local-state/useTipjarLocalState';
import { Button } from "../../components-shadcn/ui/button";
import ReactCountryFlag from "react-country-flag"

const CurrencySelector = ({ onCurrencyChange, initialCurrency = "KES" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies.find(c => c.code === initialCurrency) || currencies[0]
  );

  const handleSelectCurrency = (currency) => {
    setSelectedCurrency(currency);
    setIsOpen(false);
    if (onCurrencyChange) {
      onCurrencyChange(currency.code);
    }
  };

  // Filter currencies based on search term
  const filteredCurrencies = currencies.filter(currency => 
    currency.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    currency.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Helper to get country code for flag
  const getCountryCode = (currencyCode) => {
    const mappings = {
      'USD': 'US',
      'EUR': 'EU',
      'GBP': 'GB',
      'AED': 'AE',
      'AFN': 'AF',
      'ALL': 'AL',
      'AMD': 'AM',
      'ANG': 'AN',
      'AOA': 'AO',
      'ARS': 'AR',
      'AUD': 'AU',
      'AWG': 'AW',
      'AZN': 'AZ',
      'BAM': 'BA',
      'BBD': 'BB',
      'BDT': 'BD',
      'BGN': 'BG',
      'BHD': 'BH',
      'BIF': 'BI',
      'BMD': 'BM',
      'BND': 'BN',
      'BOB': 'BO',
      'BRL': 'BR',
      'BSD': 'BS',
      'BWP': 'BW',
      'BYN': 'BY',
      'BZD': 'BZ',
      'CAD': 'CA',
      'CDF': 'CD',
      'CHF': 'CH',
      'CLP': 'CL',
      'CNY': 'CN',
      'COP': 'CO',
      'CRC': 'CR',
      'CVE': 'CV',
      'CZK': 'CZ',
      'DJF': 'DJ',
      'DKK': 'DK',
      'DOP': 'DO',
      'DZD': 'DZ',
      'EGP': 'EG',
      'ETB': 'ET',
      'FJD': 'FJ',
      'FKP': 'FK',
      'GEL': 'GE',
      'GHS': 'GH',
      'GIP': 'GI',
      'GMD': 'GM',
      'GNF': 'GN',
      'GTQ': 'GT',
      'GYD': 'GY',
      'HKD': 'HK',
      'HNL': 'HN',
      'HRK': 'HR',
      'HTG': 'HT',
      'HUF': 'HU',
      'IDR': 'ID',
      'ILS': 'IL',
      'INR': 'IN',
      'ISK': 'IS',
      'JMD': 'JM',
      'JPY': 'JP',
      'KES': 'KE',
      'KGS': 'KG',
      'KHR': 'KH',
      'KMF': 'KM',
      'KRW': 'KR',
      'KWD': 'KW',
      'KYD': 'KY',
      'KZT': 'KZ',
      'LAK': 'LA',
      'LBP': 'LB',
      'LKR': 'LK',
      'LRD': 'LR',
      'LSL': 'LS',
      'MAD': 'MA',
      'MDL': 'MD',
      'MGA': 'MG',
      'MKD': 'MK',
      'MMK': 'MM',
      'MNT': 'MN',
      'MOP': 'MO',
      'MRO': 'MR',
      'MUR': 'MU',
      'MVR': 'MV',
      'MWK': 'MW',
      'MXN': 'MX',
      'MYR': 'MY',
      'MZN': 'MZ',
      'NAD': 'NA',
      'NGN': 'NG',
      'NIO': 'NI',
      'NOK': 'NO',
      'NPR': 'NP',
      'NZD': 'NZ',
      'PAB': 'PA',
      'PEN': 'PE',
      'PGK': 'PG',
      'PHP': 'PH',
      'PKR': 'PK',
      'PLN': 'PL',
      'PYG': 'PY',
      'QAR': 'QA',
      'RON': 'RO',
      'RSD': 'RS',
      'RUB': 'RU',
      'RWF': 'RW',
      'SAR': 'SA',
      'SBD': 'SB',
      'SCR': 'SC',
      'SEK': 'SE',
      'SGD': 'SG',
      'SHP': 'SH',
      'SLL': 'SL',
      'SOS': 'SO',
      'SRD': 'SR',
      'STD': 'ST',
      'SZL': 'SZ',
      'THB': 'TH',
      'TJS': 'TJ',
      'TOP': 'TO',
      'TRY': 'TR',
      'TTD': 'TT',
      'TWD': 'TW',
      'TZS': 'TZ',
      'UAH': 'UA',
      'UGX': 'UG',
      'UYU': 'UY',
      'UZS': 'UZ',
      'VND': 'VN',
      'VUV': 'VU',
      'WST': 'WS',
      'XAF': 'CF',
      'XCD': 'AG',
      'XOF': 'BF',
      'XPF': 'PF',
      'YER': 'YE',
      'ZAR': 'ZA',
      'ZMW': 'ZM'
    };
    return mappings[currencyCode] || 'US';
  };

  return (
    <div className="w-full max-w-[600px] bg-gray-100 rounded-xl p-4">
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <div className="flex items-center justify-between cursor-pointer">
            <span className="text-md text-black font-semibold">Select currency</span>
            <div className="flex items-center gap-2">
              <span className="font-medium">{selectedCurrency.code}</span>
              <ReactCountryFlag
                countryCode={getCountryCode(selectedCurrency.code)}
                svg
                style={{ width: '1.5em', height: '1.5em' }}
                title={selectedCurrency.code}
              />
              <ChevronRight className="h-4 w-4" />
            </div>
          </div>
        </SheetTrigger>
        
        <SheetContent side="bottom" className="h-[80vh] w-full">
          <SheetHeader>
            <SheetTitle>Select Currency</SheetTitle>
          </SheetHeader>
          
          <div className="relative mt-4 mb-6">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 pr-4"
              placeholder="Search currencies..."
            />
            {searchTerm && (
              <button 
                onClick={() => setSearchTerm('')}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                <X size={16} className="text-gray-400" />
              </button>
            )}
          </div>

          <ScrollArea className="h-[calc(100vh-250px)]">
            <div className="space-y-2">
              {filteredCurrencies.map((currency) => (
                <div
                  key={currency.code}
                  className={cn(
                    "flex items-center justify-between p-3 rounded-lg cursor-pointer hover:bg-gray-100",
                    selectedCurrency.code === currency.code && "bg-gray-100"
                  )}
                  onClick={() => handleSelectCurrency(currency)}
                >
                  <div className="flex items-center gap-3">
                    <ReactCountryFlag
                      countryCode={getCountryCode(currency.code)}
                      svg
                      style={{ width: '2em', height: '2em' }}
                      title={`${currency.code} - ${currency.name}`}
                    />
                    <div>
                      <p className="font-medium">{currency.name}</p>
                      <p className="text-sm text-gray-600">{currency.code}</p>
                    </div>
                  </div>
                  {selectedCurrency.code === currency.code && (
                    <div className="w-3 h-3 rounded-full bg-green-500" />
                  )}
                </div>
              ))}
            </div>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </div>
  );
};

const MultipleCurrencySelector = () => {
    const { setSelectedCurency, selectedCurrency } = useTipJarLocalState();

    const handleCurrencyChange = (currencyCode) => {
      setSelectedCurency(currencyCode);
    };

    return (
        <div className="mb-4">
          <CurrencySelector 
            onCurrencyChange={handleCurrencyChange}
            initialCurrency={selectedCurrency}
          />  
        </div>
    );
}

export default MultipleCurrencySelector