import React, { useState } from 'react';
import { Button } from "../../../components-shadcn/ui/button";
import { Label } from "../../../components-shadcn/ui/label";
import { ArrowRight, ArrowLeft } from "lucide-react";
import ShukranTagLine from "../../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useNavigate } from 'react-router-dom';

const UploadDocuments = () => {
    const navigate=useNavigate()
    const [files, setFiles] = useState({
        frontId: null,
        backId: null,
        selfie: null
    });

    const handleFileChange = (e, fileType) => {
        const file = e.target.files[0];
        setFiles(prev => ({
            ...prev,
            [fileType]: file
        }));
    };

    return (
        <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
            <div className="w-full flex justify-start mt-8 mb-16 p-3">
                <img src={ShukranTagLine} alt="Shukran Logo" className="w-32" />
            </div>

            <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
                <h1 className="text-[#44CBBE] text-2xl font-medium mb-3">Upload Documents</h1>
                <p className="text-gray-600 mb-8">
                    Kindly take a picture 📸 of your documents or add from your camera roll
                </p>

                <form className="space-y-6">
                    <div className="space-y-4">
                        <div className="space-y-2">
                            <Label className="text-sm text-gray-500">National ID (Front)</Label>
                            <div className="flex items-center gap-4">
                                <Button 
                                    type="button"
                                    variant="outline"
                                    className="bg-[#F5F6F8] border-[#CCD3DB] hover:bg-[#E8E9EB] w-32"
                                    onClick={() => document.getElementById('frontId').click()}
                                >
                                    Choose File
                                </Button>
                                <span className="text-sm text-gray-500">
                                    {files.frontId ? files.frontId.name : 'No file selected'}
                                </span>
                                <input
                                    id="frontId"
                                    type="file"
                                    className="hidden"
                                    onChange={(e) => handleFileChange(e, 'frontId')}
                                    accept="image/*"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <Label className="text-sm text-gray-500">National ID (Back)</Label>
                            <div className="flex items-center gap-4">
                                <Button 
                                    type="button"
                                    variant="outline"
                                    className="bg-[#F5F6F8] border-[#CCD3DB] hover:bg-[#E8E9EB] w-32"
                                    onClick={() => document.getElementById('backId').click()}
                                >
                                    Choose File
                                </Button>
                                <span className="text-sm text-gray-500">
                                    {files.backId ? files.backId.name : 'No file selected'}
                                </span>
                                <input
                                    id="backId"
                                    type="file"
                                    className="hidden"
                                    onChange={(e) => handleFileChange(e, 'backId')}
                                    accept="image/*"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <Label className="text-sm text-gray-500">Passport photo (Selfie)</Label>
                            <div className="flex items-center gap-4">
                                <Button 
                                    type="button"
                                    variant="outline"
                                    className="bg-[#F5F6F8] border-[#CCD3DB] hover:bg-[#E8E9EB] w-32"
                                    onClick={() => document.getElementById('selfie').click()}
                                >
                                    Choose File
                                </Button>
                                <span className="text-sm text-gray-500">
                                    {files.selfie ? files.selfie.name : 'No file selected'}
                                </span>
                                <input
                                    id="selfie"
                                    type="file"
                                    className="hidden"
                                    onChange={(e) => handleFileChange(e, 'selfie')}
                                    accept="image/*"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between mt-8">
                        <Button 
                            type="button" 
                            variant="outline"
                            className="bg-[#F5F6F8] hover:bg-[#E8E9EB] rounded-full px-6"
                        >
                            <ArrowLeft className="mr-2 h-4 w-4" />
                            Previous
                        </Button>
                        <Button 
                            onClick={()=>navigate("/agree-to-terms/")} 
                            className="bg-[#44CBBE] hover:bg-[#3BB7AA] text-white rounded-full px-6"
                        >
                            Next
                            <ArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UploadDocuments;