import React, { useState, useEffect } from 'react';
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";
import { Search, X } from 'lucide-react';
import { Checkbox } from "../../components-shadcn/ui/checkbox";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useNavigate } from "react-router-dom";
import { OctagonAlert } from "lucide-react";

const AddIndividualsToEntityTip = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedIndividuals, setSelectedIndividuals] = useState([]);
    const navigate = useNavigate();
    const { entityJARMembers, setSelectedIndividualsToTip, activeEntity,defaultEntityJAR } = useTipJarLocalState();
    const [members, setMembers] = useState(entityJARMembers);

     useEffect(() => {
        if(defaultEntityJAR?.jar_members_can_receive_individual_tip===false){
              const filteredMembers= entityJARMembers?.filter((member) => member.can_join_entity_jar === false);
              setMembers(filteredMembers);
          }
      }, [defaultEntityJAR, entityJARMembers]);

    const handleBack = () => {
        setSelectedIndividualsToTip([]);
        navigate(-1);
    };

    const filteredIndividuals = members?.filter((individual) =>
        `${individual.user.first_name} ${individual.user.last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
    );

    const toggleIndividual = (individual) => {
        setSelectedIndividuals((prev) =>
            prev.some((i) => i.id === individual.id)
                ? prev.filter((i) => i.id !== individual.id)
                : [...prev, individual]
        );
    };

    const removeSelectedIndividual = (individual) => {
        setSelectedIndividuals((prev) =>
            prev.filter((i) => i.id !== individual.id)
        );
    };

    const handleProceed = () => {
        setSelectedIndividualsToTip(selectedIndividuals);
        navigate("/tip-amount-calculator");
    };

    return (
        <div className="flex flex-col h-screen bg-white px-4 overflow-y-hidden">
            <div className="flex items-center mb-6 w-full">
                <div className="w-full flex flex-row items-center justify-between">
                    <BackButton onPress={handleBack} />
                    <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
                </div>
            </div>

            <h1 className="text-[#50A2A7] text-2xl font-medium mb-6">
                {`Search individuals from ${activeEntity?.name} team`}
            </h1>

            <p className="mb-2">
                Add Individuals: {selectedIndividuals.length} selected
            </p>

            {selectedIndividuals.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                    {selectedIndividuals.map((individual) => (
                        <Button
                            key={individual.id}
                            variant="secondary"
                            className="bg-[#CBE3E5] text-black rounded-full text-sm py-1 px-3"
                            onClick={() => removeSelectedIndividual(individual)}
                        >
                            {`${individual.user.first_name} ${individual.user.last_name}`}
                            <X size={14} className="ml-1" />
                        </Button>
                    ))}
                </div>
            )}

            <div className="relative mb-4">
                <Search
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={20}
                />
                <Input
                    className="pl-10 pr-4 w-full border-2 border-[#6CCBD1] rounded-full h-[60px]"
                    placeholder="Search name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <div className="flex-grow overflow-auto">
                {filteredIndividuals?.length === 0 ? (
                    <div className="w-full flex flex-col items-center gap-2 mt-10">
                        <OctagonAlert size={24} className="text-gray-400" />
                        <p className="text-sm">No Members yet</p>
                    </div>
                ) : (
                    filteredIndividuals?.map((individual) => {
                        const isSelected = selectedIndividuals.some(
                            (i) => i.id === individual.id
                        );
                        return (
                            <div
                                key={individual.id}
                                className="flex items-center p-3 mb-2 rounded-lg border border-[#F5F5F5] cursor-pointer"
                            >
                                <div
                                    className="flex-grow flex items-center"
                                    onClick={() => toggleIndividual(individual)}
                                >
                                    <div className="w-10 h-10 rounded-full bg-[#6CCBD1] text-white flex items-center justify-center mr-3">
                                        {individual.user.first_name[0]}
                                    </div>
                                    <div>
                                        <p className="font-semibold">
                                            {`${individual.user.first_name} ${individual.user.last_name}`}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {individual.user.shukran_id}
                                        </p>
                                    </div>
                                </div>
                                <Checkbox
                                    checked={isSelected}
                                    onCheckedChange={() => toggleIndividual(individual)}
                                    className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
                                />
                            </div>
                        );
                    })
                )}
            </div>

            <div className="w-full p-4">
                <Button
                    className="w-full bg-[#F2C773] hover:bg-[#e0b66a] text-white h-[60px] rounded-full"
                    onClick={handleProceed}
                    disabled={selectedIndividuals.length === 0}
                >
                    PROCEED
                </Button>
            </div>
        </div>
    );
};

export default AddIndividualsToEntityTip;