import { useEffect, useState } from "react";
import BackButton from "../globals/BackButton";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import { Hotel, Users, Users2, OctagonAlert } from "lucide-react";
import TipOption from "./TipOption";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import EntityJarIcon from "../../assets/Images/entity-jar-icon.png";
import IndividualJarIcon from "../../assets/Images/individual-jar-icon.png";
import GroupJarIcon from "../../assets/Images/group-tipjar-icon.png";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { Button } from "../../components-shadcn/ui/button";
import { useFetchEntityMembersMutation } from "../Tipjar/state/server-state/useTipJarServerState";

const TipGroupsHome = () => {
  const url = new URL(window.location.href);
  const { uid } = useParams();
  const navigate = useNavigate();
  const [defaultJAR, setDefaultJAR] = useState(null);
  const [entityJars, setJars] = useState([]);
  const fetchEntityMembers = useFetchEntityMembersMutation();
  const isFetchingEntityMembers = fetchEntityMembers.isLoading;

  const {
    activeEntity,
    setActiveEntity,
    setActiveEntityJAR,
    selectedType,
    setSelectedType,
    setEntityJARMembers,
    defaultEntityJAR,
    setEntityToTip,
    setEntityJARToTip,
    setDefaultEntityJAR,
  } = useTipJarLocalState();

  useEffect(() => {
    let entityToTip = JSON.parse(localStorage.getItem("entityToTip"));
    if (entityToTip) {
      setDefaultEntityJAR(entityToTip);
      handleFetchEntityMembers(entityToTip.id);
    }
  }, []);

  const handleFetchEntityMembers = async (entityId) => {
    try {
      const response = await fetchEntityMembers.mutateAsync(entityId);
      const activeMembers = response?.data?.filter((el) => el.active);
      setEntityJARMembers(activeMembers);
    } catch (error) {
      console.log("error", error);
      toast.error("Could not fetch entity members");
    }
  };

  useEffect(() => {
    if (defaultEntityJAR) {
      setJars(defaultEntityJAR.entity_jars || []);

      const entityObject = {
        id: defaultEntityJAR.entity_jar.entity,
        name: defaultEntityJAR.name,
        owner_id: defaultEntityJAR.entity_jar.user,
        entity_jar: {
          id: defaultEntityJAR.entity_jar.id,
          active: defaultEntityJAR.entity_jar.active,
          created_at: defaultEntityJAR.entity_jar.created_at,
          updated_at: defaultEntityJAR.entity_jar.updated_at,
          name: defaultEntityJAR.entity_jar.name,
          jar_type: defaultEntityJAR.entity_jar.jar_type,
          is_default_entity_jar: defaultEntityJAR.entity_jar.is_default_entity_jar,
          entity: defaultEntityJAR.entity_jar.entity,
          user: defaultEntityJAR.entity_jar.user,
        },
        entity_type: "RESTAURANT",
        created_at: defaultEntityJAR.entity_jar.created_at,
        default_group: defaultEntityJAR.default_group.default_group,
        shukran_id: defaultEntityJAR.shukran_id || "",
      };

      setActiveEntity(entityObject);
      setDefaultJAR(defaultEntityJAR.entity_jar);
    }
  }, [defaultEntityJAR]);

  const handleBack = () => {
    navigate(-1);
  };

  const navigateToTipGroupsAndSearchMembers = (type, entityJAR) => {
    if (defaultEntityJAR?.active || type === "individual") {
      setSelectedType(type);
      if (type === "entityJAR") {
        setActiveEntityJAR(entityJAR);
      }
      navigate("/tip-groups-and-search-members");
    }
  };

  const navigateToTipEntity = () => {
    setEntityToTip(activeEntity);
    setEntityJARToTip(null);
    navigate("/tip-entity/");
  };

  const navigateToTipEntityJar=(type, entityJAR)=>{
    setEntityJARToTip(entityJAR);
    setEntityToTip(null);
    navigate("/tip-entity/")
    
  }

  const handleContactSupport = () => {
    const supportUrl = "https://wa.link/aigch7";
    window.open(supportUrl, '_blank');
  };

  return (
    <div className="flex flex-col w-full h-screen px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      {defaultEntityJAR && !defaultEntityJAR.active && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
          <div className="flex flex-col">
            <p className="text-red-700 font-medium mb-2">
              Sorry this business QR is not activated for tips
            </p>
            <Button 
              onClick={handleContactSupport}
              className="w-fit text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-full text-sm"
            >
              Contact support
            </Button>
          </div>
        </div>
      )}

      {!activeEntity ? (
        <Skeleton className="w-full h-[100px] rounded" />
      ) : (
        <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-1">
          Who would you like to tip at {activeEntity.name}?
        </h1>
      )}

      <div className="space-y-4 mt-4 pb-4">
        {defaultEntityJAR?.main_tipjar_enabled && (
          <>
            <h3 className="font-semibold text-lg text-slate-600">
              Tip the Entire Hotel Team
            </h3>
            {!activeEntity ? (
              <Skeleton className="w-full h-[100px] rounded" />
            ) : (
              <TipOption
                icon={<Hotel size={24} />}
                title="Hotel TipJAR"
                image={EntityJarIcon}
                subtitle={activeEntity?.name}
                strokeColor="#F5E1BA"
                fillColor={!defaultEntityJAR?.active ? "#CCD3DB" : "#FFFCF5"}
                handleClick={navigateToTipEntity}
                currEntity={activeEntity}
                type="entity"
                disabled={!defaultEntityJAR?.active}
              />
            )}
          </>
        )}

        {defaultEntityJAR?.individuals_enabled && (
          <>
            <h3 className="font-semibold text-lg text-slate-600">
              Tip Individuals
            </h3>
            <TipOption
              icon={<Users size={24} />}
              title="Individuals"
              image={IndividualJarIcon}
              subtitle={activeEntity?.name}
              strokeColor="#ABDADD"
              fillColor="#F5FEFF"
              type="individual"
              handleClick={navigateToTipGroupsAndSearchMembers}
              disabled={false}
            />
          </>
        )}

        {defaultEntityJAR?.subtipjars_enabled && (
          <>
            {entityJars?.filter((entity)=>entity.is_default_entity_jar===false).length>0 && 
              <h3 className="font-semibold text-lg text-slate-600">Tip Sub TipJARs</h3>
            }
            
            {!entityJars || entityJars.length === 0 ? (
              <div className="w-full flex flex-col items-center gap-2">
                <OctagonAlert size={24} className="text-gray-400" />
                <p className="text-sm">No other JARs found</p>
              </div>
            ) : (
              <>
                {entityJars?.filter((entity)=>entity.is_default_entity_jar===false).map((entity) => (
                  <TipOption
                    key={entity.id}
                    icon={<Users2 size={24} />}
                    title="Group TipJAR"
                    image={GroupJarIcon}
                    subtitle={entity?.name}
                    strokeColor="#C5B7DF"
                    type="entityJAR"
                    entityJAR={entity}
                    handleClick={navigateToTipEntityJar}
                    fillColor={!defaultEntityJAR?.active ? "#CCD3DB" : "#F8F5FF"}
                    disabled={!defaultEntityJAR?.active}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TipGroupsHome;